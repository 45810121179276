<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Question Type</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </b-col>
        <!--
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
        -->
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">New Question Type</h3>
              <!-- Card body -->
              <form>
                <!-- Input groups with icon -->
                <b-row>
                  <b-col md="6">
                    <base-input label="Question Type Name" placeholder="Question Type name" v-model="question_type.question_type_name"></base-input>
                  </b-col>
                </b-row>

                <base-button type="primary" @click="createData()">Create / Save</base-button>
                <base-button type="primary" @click="$router.back()">Cancel</base-button>
              </form>
            </card>

          </div>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
  import axios from 'axios'
  import {apiDomain, getHeader} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],  
    data() {
      return {
        question_type: {
          question_type_name: null,
        },
      }
    },
    created() {
    },
    methods: {
      createData(){
        if(!this.question_type.question_type_name){
            this.redErrorNotify( '<b>Invalid Input : Question Type name is Mandatory </b>')
        }else{
          const postData = {
            question_type_name : this.question_type.question_type_name,
          }
          const str = JSON.stringify(postData);
          console.log(str);
          console.table(postData);

          axios.post(apiDomain + 'setup/question_type', postData ,{ headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              this.$notify({
                message:
                  '<b>New Question Type : ' + response.data.data[0].question_type_name + '</b> - Successfully Created.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });
              this.$router.back()

            }
          })
          .catch ( error => {
              console.log(error.response.status)
              this.errorFunction(error, "Add Question Type")
              
          });
        }
      },

    }
  }
</script>
