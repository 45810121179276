<template>
    <div>
        <notifications></notifications>
        <base-header class="pb-6">
            <b-row class="align-items-center py-4">
                <b-col lg="6" cols="7">
                <!-- <h6 class="h2 text-white d-inline-block mb-0">KYC Form</h6>
                <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                    <route-breadcrumb/>
                </nav> -->
                </b-col>
                <b-col lg="6" cols="7" class="text-right">
                    <!-- <base-button size="sm" type="primary" @click="close()">Close</base-button> -->
                    <!-- <base-button size="sm" type="neutral">Filters</base-button> -->
                </b-col>
            </b-row>

        </base-header>

        <b-container class="mt--6" v-if="otp_code == null">
            <b-row align-v="center" class="justify-content-center" >
                <b-card style="text-align: center;">
                    <h3>WELCOME</h3>
                    <b-col class="customer_info" v-if="customer_form">
                        <b-row>
                            <b-col md="4" class="text-left"><p>Title: </p></b-col>
                            <b-col class="text-left"><p>{{customer_form.customer_form_name}}</p></b-col>
                        </b-row>
                        <b-row>
                            <b-col md="4" class="text-left"><p>Description:</p></b-col>
                            <b-col class="text-left" v-if="customer_form.customer_form_description != null"><p>{{customer_form.customer_form_description}}</p></b-col>
                            <b-col class="text-left" v-else><p>-</p></b-col>
                        </b-row>
                        <br>
                        <b-row>
                            <b-col md="4" class="text-left"><p>Send by (SAS) Advisor:</p></b-col>
                            <b-col class="text-left"><p>{{customer_form.created_by.name}}</p></b-col>
                        </b-row>
                        <br>
                        <template v-if="customer">
                            <b-row>
                                <b-col md="4" class="text-left"><p>To</p></b-col>
                                <b-col class="text-left"><p>{{customer.customer_name}}</p></b-col>
                            </b-row>
                            <b-row>
                                <b-col md="4" class="text-left"><p>Email</p></b-col>
                                <b-col class="text-left"><p>{{customer.customer_email}}</p></b-col>
                            </b-row>
                        </template>
                        
                    </b-col>
                    <vie-otp-input
                    ref="otpInput"
                    input-classes="otp-input"
                    separator="-"
                    :num-inputs="6"
                    :should-auto-focus="true"
                    :is-input-num="true"
                    @on-change="handleOnChange"
                    @on-complete="validateCustomerFormOTP"
                    />
                    <br>
                    <b-col class="text-center">
                        <!-- <base-button size="sm" type="primary" @click="handleClearInput()">Clear Input</base-button> -->
                    </b-col>
                    <!-- <div class="w-100 p-4 d-flex align-items-center justify-content-center"> -->
                        <!-- <base-button type="primary"  @click="handleSubmit()">Submit</base-button> -->
                    <!-- </div> -->
                </b-card>
            </b-row>
        </b-container>

        <b-container class="mt--6" v-else-if="otp_code == 'expired'">
            <b-row align-v="center" class="justify-content-center" >
                <b-card style="text-align: center;">
                    <h3>Your OTP has Expired. Please click on the button to receive new OTP code.</h3>
                    <br>
                    <b-col class="text-center">
                        <base-button size="sm" type="primary" @click="generateNewOTP()">Generate New OTP Code</base-button>
                    </b-col>
                </b-card>
            </b-row>
        </b-container>

        <b-container class="container-size mt--6" v-else-if="!submit">
            <b-row align-v="center" class="justify-content-center">
                <!-- <b-col lg="12"> -->
                <div class="card-wrapper">
                    <!-- Input global_templates -->
                    <card class="bg-default card-size" style="text-align: center;">
                    <!-- Card header -->
                    <b-row>
                        <b-card-title style="padding-left: 15px" title-tag="h1" class="bg-default text-white">Personal Monthly Commitment KYC</b-card-title>
                        <b-col class="text-right">
                            <base-button class="btn-size" type="primary" icon size="sm" @click.prevent="close()">Close</base-button>
                        </b-col>
                    </b-row>
                    
                    <!-- <h3 slot="header" class="mb-0">Personal Monthly Commitment KYC</h3> -->
                    <!-- Card body -->
                    <validation-observer  ref="formValidator">
                    <form v-if="customerQuestion.length != 0">
                        <!-- Input global_templates with icon -->
                        <b-row class="text-white text-left">
                            <b-col md="3">
                                <base-input>Name: {{customer.customer_name}}</base-input>
                            </b-col>
                            <b-col md="6">
                                <base-input>Email: {{customer.customer_email}}</base-input>
                            </b-col>
                        </b-row>
                        
                        <b-row class="text-white text-left">
                            <b-col md="3">
                                <base-input v-model="customerQuestion.customer_template_category.customer_category.customer_category_name" disabled>Category: {{customerQuestion.customer_template_category.customer_category.customer_category_name}}</base-input>
                            </b-col>
                            <b-col md="6">
                                <base-input v-model="customerQuestion.customer_template_category.customer_sub_category.customer_sub_category_name"  disabled>Sub Category: {{customerQuestion.customer_template_category.customer_sub_category.customer_sub_category_name}}</base-input>
                            </b-col>
                            <b-col md="3" class="text-right">
                                Question: {{customerQuestionDatas.indexOf(current_customer_question_id) + 1}} / {{customerQuestionDatas.length}}
                            </b-col>
                        </b-row>
                        <!-- card sub body -->
                        <div class="bg-default card-sub-body">
                            <base-input type="hidden" :value="current_customer_question_id = customerQuestion.customer_question_id"></base-input>
                            <h3 class="text-white"><strong>{{customerQuestion.customer_question_title}} ?</strong></h3>
                            <!-- <br> -->
                            <!-- yes / no button -->
                            <template>
                                <div v-show="yesNoStatus">
                                    <b-row class="justify-content-center">
                                        <base-button class="btn-size" type="primary" icon size="sm" @click.prevent="answerStatus = true; yesNoStatus = false;">Yes</base-button>
                                        <template v-if="customerQuestionDatas[customerQuestionDatas.length - 1] != current_customer_question_id">
                                            <base-button class="btn-size" type="primary" size="sm" @click="nextQuestion(customerQuestion.customer_question_id + 1, customerQuestion.question_type.question_type_id)">No</base-button>
                                        </template>
                                        <template v-else>
                                            <base-button class="btn-size" type="primary" size="sm" @click="submitForm()">No</base-button>
                                        </template>                                    
                                    </b-row>
                                </div>
                                <div v-show="answerStatus">
                                    <b-col v-if="customerQuestion.question_type.question_type_id != 2">
                                        <b-row class="form-group">
                                            <label md="1" class="col-form-label form-control-label text-white">Amount :</label>
                                            <b-col md="10">
                                                <base-input name="Answer" placeholder="Your Answer" v-model="customer_value"></base-input>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <b-col v-else>
                                        <b-form-radio-group v-model="customer_value">
                                            <b-form-radio
                                                value="Yes"
                                                name="Yes"
                                                class="mb-3 text-white">
                                                Yes
                                            </b-form-radio>
                                            <b-form-radio
                                                value="No"
                                                name="No"
                                                class="mb-3 text-white">
                                                No
                                            </b-form-radio>
                                        </b-form-radio-group>
                                    </b-col>
                                </div>
                            </template>
                            <b-col class="text-left text-red">
                                <p>(Press next if this question is irrelevant)</p>
                            </b-col>
                        </div>
                        <b-row class="justify-content-center">
                            <template v-if="customerQuestionDatas[0] != current_customer_question_id">
                                <base-button class="btn-size" type="primary" size="sm" @click="previousQuestion(customerQuestion.customer_question_id)">Previous</base-button>
                            </template>
                            <template v-if="customerQuestionDatas[customerQuestionDatas.length - 1] != current_customer_question_id">
                                <base-button class="btn-size" type="primary" size="sm" @click="nextQuestion(customerQuestion.customer_question_id + 1, customerQuestion.question_type.question_type_id)">Next</base-button>
                            </template>
                            <template v-else>
                                <base-button class="btn-size" type="primary" size="sm" @click="submitForm()">Submit</base-button>
                            </template>
                        </b-row>
                    </form>
                    <form v-else-if="errorMessage != null" style="text-align: center;">
                        <b-col>
                            {{errorMessage}}
                        </b-col>
                        <b-col>
                            <base-button type="primary" icon size="sm" @click.prevent="home()">Home</base-button>
                        </b-col>
                    </form>
                    <form v-else style="text-align: center;">
                        <b-col class="text-white">
                            You have submitted this form.
                        </b-col>
                        <br>
                        <!-- <b-col>
                            <base-button type="primary" icon size="sm" @click.prevent="home()">Home</base-button>
                        </b-col> -->
                    </form>
                    
                    </validation-observer>
                    </card>
                    <b-col class="text-center">
                        <base-button class="btn-size" type="primary" icon size="sm" @click.prevent="close()">Close</base-button>
                    </b-col>
                </div>
            </b-row>
        </b-container>

        <b-container v-else class="mt--6">
            <b-row class="justify-content-center" >
                <b-card style="width: 50rem; text-align: center;"> 
                    <b-col>
                        <h3>Congratulations. You have finished all the questions. Thank you for taking your time.</h3> 
                    </b-col>
                    <br>
                    <b-row class="justify-content-center">
                        <base-button class="btn-size" type="primary" icon size="sm" @click.prevent="close()">Close</base-button>
                        <base-button class="btn-size" type="primary" icon size="sm" @click.prevent="reanswer()">Re-answer</base-button>
                    </b-row>
                </b-card>
            </b-row>
            <!-- <b-row class="justify-content-center">
                <b-col md="6">
                    <label for="">Month Commitment</label>
                    <apexchart type="pie" width="400" :options="chartOptions" :series="monthSeries"></apexchart>
                </b-col>
                <b-col md="6">
                    <label for="">Annual Commitment</label>
                    <apexchart type="pie" width="400" :options="chartOptions" :series="annualSeries"></apexchart>
                </b-col>
            </b-row>
            <b-row class="justify-content-center">
                <b-col md="6">
                    <label for="">Month Commitment</label>
                    <apexchart type="radar" width="500" :options="radarChartOptions" :series="radarMonthSeries"></apexchart>
                </b-col>
                <b-col md="6">
                    <label for="">Annual Commitment</label>
                    <apexchart type="radar" width="500" :options="radarChartOptions" :series="radarAnnualSeries"></apexchart>
                </b-col>
            </b-row> -->
            <!-- <div id="capture" style="padding: 10px; background: #f5da55">
                <h4 style="color: #000; ">Hello world!</h4>
            </div> -->
            <b-row class="justify-content-center">
                <!-- <label for="">Month Commitment</label> -->
                <apexchart type="pie" width="100%" :options="chartOptions" :series="monthSeries"></apexchart>
                <apexchart type="pie" width="100%" :options="chartSubOptions" :series="monthSubSeries"></apexchart>
                <apexchart type="radar" width="500" :options="radarChartOptions" :series="radarMonthSeries"></apexchart>
                <apexchart type="radar" width="500" :options="radarChartSubOptions" :series="radarMonthSubSeries"></apexchart>
            </b-row>
            <!-- <b-row class="justify-content-center">
                <label for="">Annual Commitment</label>
                <apexchart type="pie" width="400" :options="chartOptions" :series="annualSeries"></apexchart>
                <apexchart type="radar" width="600" :options="radarChartOptions" :series="radarAnnualSeries"></apexchart>
            </b-row> -->
            
        </b-container>
    </div>
</template>
<script>
import axios from 'axios'
import {apiDomain, homeLink} from '@/assets/js/config.js'
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import OtpInput from "@bachdgvn/vue-otp-input";
import VueApexCharts from 'vue-apexcharts';
import html2canvas from 'html2canvas';

export default {
    mixins: [ErrorFunctionMixin],
    components: {
        'vie-otp-input': OtpInput,
        "apexchart": VueApexCharts,
    },
    data() {
      return {
        customer: null,
        customer_form: null,
        customerQuestion: [],
        customerQuestionDatas: [],
        current_customer_question_id : null,
        customer_value: null,
        errorMessage: null,
        otp_code: null,
        submit: null,
        answerStatus: false,
        yesNoStatus: true,
        customerAnswerDatas: [],
        totalCustomerMonthly: [],
        totalCustomerAnnually: [],
        totalSubCustomerMonthly: [],
        totalSubCustomerAnnually: [],
        totalCustomerCommitment: null,
        monthSeries: [],
        monthSubSeries: [],
        annualSeries: [],
        chartOptions: {
            chart: {
                type: 'pie',
            },
            responsive: [
                {
                    breakpoint: 500,
                    options: {
                        chart: {
                            width: 350
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                },
                {
                    breakpoint: 1000,
                    options: {
                        chart: {
                            width: 400
                        },
                        legend: {
                            position: 'right'
                        }
                    }
                },
                {
                    breakpoint: 2000,
                    options: {
                        chart: {
                            width: 500
                        },
                        legend: {
                            position: 'right'
                        }
                    }
                },
                {
                    breakpoint: 3000,
                    options: {
                        chart: {
                            width: 650
                        },
                        legend: {
                            position: 'right'
                        }
                    }
                },
            ],
            legend: {
                formatter: function(val, opts) {
                    return val + " - RM " + opts.w.globals.series[opts.seriesIndex].toFixed(2)
                }
            },
            labels: [],
        },
        chartSubOptions: {
            chart: {
                type: 'pie',
            },
            responsive: [
                {
                    breakpoint: 500,
                    options: {
                        chart: {
                            width: 350
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                },
                {
                    breakpoint: 800,
                    options: {
                        chart: {
                            width: 450
                        },
                        legend: {
                            position: 'right'
                        }
                    }
                },
                {
                    breakpoint: 1000,
                    options: {
                        chart: {
                            width: 480
                        },
                        legend: {
                            position: 'right'
                        }
                    }
                },
                {
                    breakpoint: 2000,
                    options: {
                        chart: {
                            width: 550
                        },
                        legend: {
                            position: 'right'
                        }
                    }
                },
                {
                    breakpoint: 3000,
                    options: {
                        chart: {
                            width: 690
                        },
                        legend: {
                            position: 'right'
                        }
                    }
                },
            
            ],
            legend: {
                formatter: function(val, opts) {
                    return val + " - RM " + opts.w.globals.series[opts.seriesIndex].toFixed(2)
                }
            },
            labels: [],
        },
        radarMonthSeries: [{
            data: [],
        }],
        radarMonthSubSeries: [{
            data: [],
        }],
        radarAnnualSeries: [{
            data: [],
        }],
        radarChartOptions: {
            chart: {
                // width: 600,
                type: 'radar',
                toolbar: {
                    show: false,
                },
            },
            responsive: [
                {
                    breakpoint: 500,
                    options: {
                        chart: {
                            width: 480
                        },
                        legend: {
                            position: 'right'
                        }
                    }
                },
                {
                    breakpoint: 3000,
                    options: {
                        chart: {
                            width: 650
                        },
                        legend: {
                            position: 'right'
                        }
                    }
                },
            ],
            xaxis: {
                categories: [],
                labels: {
                    show: true,
                    // style: {
                    //     colors: ["#000000"],
                    //     fontSize: "12px",
                    //     fontFamily: 'Arial',
                    // }
                }
                
            },
            yaxis: {
                show: false
            },
        },
        radarChartSubOptions: {
            chart: {
                // width: 600,
                type: 'radar',
                toolbar: {
                    show: false,
                },
            },
            responsive: [
                {
                    breakpoint: 500,
                    options: {
                        chart: {
                            width: 480
                        },
                        legend: {
                            position: 'right'
                        }
                    }
                },
                {
                    breakpoint: 3000,
                    options: {
                        chart: {
                            width: 650
                        },
                        legend: {
                            position: 'right'
                        }
                    }
                },
            ],
            xaxis: {
                categories: [],
                labels: {
                    show: true,
                    // style: {
                    //     colors: ["#000000"],
                    //     fontSize: "12px",
                    //     fontFamily: 'Arial',
                    // }
                }
                
            },
            yaxis: {
                show: false
            },
        }
      };
    },
    created() {
        if(window.localStorage.getItem('otp_code') != null)
        {
            this.otp_code = JSON.parse(window.localStorage.getItem('otp_code'));
            this.validateCustomerFormOTP(this.otp_code);
        }
        this.getCustomer();
        this.getCustomerForm();
        this.getCustomerUnfillQuestion();
        this.customerQuestionList();
    },  
    mounted() {
    //   this.$refs.formValidator.validate();
    },
    methods: {
        close() {
            window.close();
        }, 
        home(){
          this.$router.push({path: '/templates/global_template' });
        },
        changeStatus(yesNoStatus, answerStatus) {
            this.yesNoStatus = yesNoStatus;
            this.answerStatus = answerStatus;
        },
        // otp page function
        validateCustomerFormOTP(value) {
            const postData = {
                customer_form_id : this.$route.params.customer_form_id,
                customer_id  : this.$route.params.customer_id,
                otp_code : value,
            }
    
            axios.post(apiDomain + 'customers/customer_form.validate_customer_form_OTP', postData)
            .then(response => {
                if (response.status === 200)
                {
                    window.localStorage.setItem('otp_code', JSON.stringify(value));
                    this.otp_code = value;
                }
            })
            .catch ( error => {
                if(Object.keys(error.response.data.errors)[0] == 'OTP_Expired')
                {
                    this.otp_code = 'expired';
                    this.errorFunction(error, "Error Getting Customer Question") 
                }
                else
                {
                    this.otp_code = null;
                    this.handleClearInput();
                    this.errorFunction(error, "Error Getting Customer Question") 
                }
            });  
        },
        handleOnChange(value) {
            console.log('OTP changed: ', value);
        },
        handleClearInput() {
            this.$refs.otpInput.clearInput();
        },
        generateNewOTP() {
            const postData = {
                customer_form_id : this.$route.params.customer_form_id,
                customer_id  : this.$route.params.customer_id,
            }

            axios.put(apiDomain + 'customers/customer_form.generate_new_OTP', postData)
            .then(response => {
                if (response.status === 200)
                {
                    window.localStorage.removeItem('otp_code');
                    this.otp_code = null;
                    this.sendEmail();
                }
            })
            .catch ( error => {
                this.errorFunction(error, "Error Getting Customer Question")                 
            });
        },
        sendEmail(){
            const emailData = {
                customer_name: this.customer.customer_name,
                customer_email: this.customer.customer_email,
                customer_form_id: this.$route.params.customer_form_id,
                kyc_form_link: homeLink + this.$router.currentRoute.path,
            }
            axios.post(apiDomain + 'customers/customer_form.send_email', emailData)
            .then(response => {
                if(response.status === 200)
                {                  
                    this.$notify({
                    message:
                        '<b>New Generated OTP Code has send to ' + this.customer.customer_email + '</b>.',
                    timeout: 10000,
                    icon: 'ni ni-bell-55',
                    type: 'default',
                    });
                }
            })
            .catch ( error => {
                console.log(error.response.status)
                this.errorFunction(error, "Customer Form Error Sending Email")
            });
        },
        // kyc form question function
        getCustomer() {
            axios.get(apiDomain + 'customers/customer.get_current_customer/' + this.$route.params.customer_id)
            .then(response => {
                this.customer = response.data.data[0]
            })
            .catch ( error => {
                this.errorFunction(error, "Error Getting Customer Info") 
            });
        },
        getCustomerForm() {
            axios.get(apiDomain + 'customers/customer_question.show_form_detail/' + this.$route.params.customer_form_id)
            .then(response => {
                this.customer_form = response.data.data[0]
            })
            .catch ( error => {
                this.errorFunction(error, "Error Getting Customer Info") 
            });
        },
        getCustomerUnfillQuestion() {
            axios.get(apiDomain + 'customers/customer_question.show_unanswer_question/' + this.$route.params.customer_id + '/' + this.$route.params.customer_form_id)
            .then(response => {
                this.customerQuestion = response.data.data[0]
                this.current_customer_question_id = response.data.data[0].customer_question_id
                this.customer_value = response.data.data[0].answer.customer_value
            })
            .catch ( error => {
                // console.log(error.response.status)
                // console.log(error.response.data.errors.No_Form_For_This_Customer[0])
                this.customerAnswerList();
                // this.errorMessage = error.response.data.errors.No_Form_For_This_Customer[0]
                // this.errorFunction(error, "Error Getting Customer Question") 
            });
            const str = JSON.stringify(this.customerQuestion, null, 2);
            console.log(str);
        },
        customerQuestionList() {
            axios.get(apiDomain + 'customers/customer_question.get_question_IDs/' + this.$route.params.customer_form_id)
            .then(response => {
                console.log(response.data);
                this.customerQuestionDatas = response.data
            })
            .catch ( error => {
                console.log(error.response.status)
                this.errorFunction(error, "Error Getting Customer Question") 
            });
        },
        previousQuestion(customer_question_id) {
            axios.all([this.validateCustomerFormOTP(JSON.parse(window.localStorage.getItem('otp_code')))]);
            axios.get(apiDomain + 'customers/customer_question.get_previous_customer_question_record/' + this.$route.params.customer_form_id + '/' +customer_question_id)
            .then(response => {
                // this.customerQuestion = [];
                this.customerQuestion = response.data.data[0]
                this.customer_value = response.data.data[0].answer.customer_value
                if(this.customer_value == null)
                {
                    this.changeStatus(true, false);
                }
                else
                {
                    this.changeStatus(false, true);
                }
            })
            .catch ( error => {
                // console.log(error.response.status)
                this.errorFunction(error, "Error Getting Customer Question") 
            });
            const str = JSON.stringify(this.customerQuestion, null, 2);
            // console.log(str);
            
        },
        getNextQuestionRecord(customer_question_id) {
            axios.get(apiDomain + 'customers/customer_question.get_next_customer_question_record/' + customer_question_id)
            .then(response => {
                // console.log(response.data.data);
                this.customerQuestion = response.data.data[0];
                this.customer_value = response.data.data[0].answer.customer_value;
                if(this.customer_value == null)
                {
                    this.changeStatus(true, false);
                }
                else
                {
                    this.changeStatus(false, true);
                }

            })
            .catch ( error => {
                console.log(error.response.status)
                this.errorFunction(error, "Error Getting Customer Question") 
            });
            const str = JSON.stringify(this.customerQuestion, null, 2);
            console.log(str);        
        },
        nextQuestion(customer_question_id, question_type_id) {

            const postData = {
                customer_form_id : this.$route.params.customer_form_id,
                customer_id  : this.$route.params.customer_id,
                customer_question_id : this.current_customer_question_id,
                customer_value : this.customer_value,
                // admin_value : this.customer_value,
            }

            axios.all([this.validateCustomerFormOTP(JSON.parse(window.localStorage.getItem('otp_code')))]);

            if(question_type_id == 1)
            {
                axios.post(apiDomain + 'customers/answer', postData)
                .then(response => {
                    if (response.status === 200)
                    {
                        this.getNextQuestionRecord(customer_question_id);
                    }
                })
                .catch ( error => {
                    console.log(error.response.status)
                    this.errorFunction(error, "Error Getting Customer Question") 
                }); 
            }
            else
            {
                if(this.customer_value == 'Yes')
                {
                    axios.post(apiDomain + 'customers/answer', postData)
                    .then(response => {
                        if (response.status === 200)
                        {
                            this.getNextQuestionRecord(customer_question_id);
                        }
                    })
                    .catch ( error => {
                        console.log(error.response.status)
                        this.errorFunction(error, "Error Getting Customer Question") 
                    }); 
                }
                else
                {
                    axios.post(apiDomain + 'customers/answer', postData)
                    .then(response => {
                        if (response.status === 200)
                        {
                            axios.get(apiDomain + 'customers/customer_question.get_next_customer_question_set/' + this.$route.params.customer_form_id + '/' + this.current_customer_question_id)
                            .then(response => {
                                // console.log(response.data.data);
                                this.customerQuestion = response.data.data[0];
                                this.customer_value = response.data.data[0].answer.customer_value;
                            })
                            .catch ( error => {
                                this.$router.push({path: '/kyc_forms/submit' });
                                // console.log(error.response.status)
                                // this.errorFunction(error, "Error Getting Customer Question") 
                            });
                            const str = JSON.stringify(this.customerQuestion, null, 2);
                            console.log(str);    
                        }
                    })
                    .catch ( error => {
                        console.log(error.response.status)
                        this.errorFunction(error, "Error Getting Customer Question") 
                    }); 
                }
            }
        },
        submitForm() {
            swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to edit this form anymore!",
            icon: 'warning',
            // showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes, submit it!',
            // denyButtonText: `Don't save`,
            }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
            
                const postData = {
                    customer_form_id : this.$route.params.customer_form_id,
                    customer_id  : this.$route.params.customer_id,
                    customer_question_id : this.current_customer_question_id,
                    customer_value : this.customer_value,
                    // admin_value : this.customer_value,
                }
                // this.submit = true;
                // this.customerAnswerList();

                axios.post(apiDomain + 'customers/answer', postData)
                .then(response => {
                    if (response.status === 200)
                    {
                        // this.$router.push({path: '/kyc_forms/submit' });
                        this.customerAnswerList();
                    }
                })
                .catch ( error => {
                    console.log(error.response.status)
                    this.errorFunction(error, "Error Getting Customer Question") 
                });
                // } 
            } 
            })           
        },
        reanswer() {
            this.submit = null;
            this.getNextQuestionRecord(this.customerAnswerDatas[0].customer_question_id);
        },
        // get customer answer list
        customerAnswerList() {
            this.submit = true;

            axios.get(apiDomain + 'customers/customer_question.show_by_form/' + this.$route.params.customer_form_id)
            .then(response => {
                let uniqueCategory= [];
                let uniqueSubCategory= [];
                let chartColors = [];
                let chartSubColors = [];
                this.customerAnswerDatas = response.data.data;
                
                Object.values(this.customerAnswerDatas).forEach((data) => {
                    if(uniqueCategory.indexOf(data.customer_template_category.customer_category.customer_category_name) < 0)
                    {
                        uniqueCategory[data.customer_template_category.customer_category_sort] = data.customer_template_category.customer_category.customer_category_name;
                        this.totalCustomerMonthly[data.customer_template_category.customer_category.customer_category_name] = 0;
                        this.totalCustomerAnnually[data.customer_template_category.customer_category.customer_category_name] = 0;
                        
                        // generate random colors based on number of unique category
                        chartColors.push('#' + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0'));
                    }

                    // get sub category
                    if(uniqueSubCategory.indexOf(data.customer_template_category.customer_sub_category.customer_sub_category_name) < 0)
                    {
                        uniqueSubCategory.push(data.customer_template_category.customer_sub_category.customer_sub_category_name);
                        this.totalSubCustomerMonthly[data.customer_template_category.customer_sub_category.customer_sub_category_name] = 0;
                        this.totalSubCustomerAnnually[data.customer_template_category.customer_sub_category.customer_sub_category_name] = 0;
                        
                        // generate random colors based on number of unique category
                        chartSubColors.push('#' + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0'));
                    }
                })
                this.chartOptions = {
                    ...this.chartOptions,
                    colors: chartColors,
                    labels: uniqueCategory,
                };

                this.chartSubOptions = {
                    ...this.chartSubOptions,
                    colors: chartSubColors,
                    labels: uniqueSubCategory,
                };

                // console.log(uniqueCategory);

                this.radarChartOptions = {
                    ...this.radarChartOptions,
                    xaxis: {
                        categories: uniqueCategory,
                    },
                };

                this.radarChartSubOptions = {
                    ...this.radarChartSubOptions,
                    xaxis: {
                        categories: uniqueSubCategory,
                    },
                };

                this.calculateCustomerTotalCommitment();
                
            })
            .catch ( error => {
                console.log(error.response.status)
                this.errorFunction(error, "Error Getting Customer Question") 
            });
        },
        // to calculate total for chart
        calculateCustomerTotalCommitment() {
            Object.values(this.customerAnswerDatas).forEach((data) => {
                if(data.frequency.frequency_name == "Monthly")
                {
                    // if admin value is null get customer value
                    if(data.answer.admin_value == null)
                    {
                        this.totalCustomerMonthly[data.customer_template_category.customer_category.customer_category_name] += data.answer.customer_value;
                        this.totalCustomerAnnually[data.customer_template_category.customer_category.customer_category_name] += (data.answer.customer_value * 12);

                        // for all sub category
                        this.totalSubCustomerMonthly[data.customer_template_category.customer_sub_category.customer_sub_category_name] += data.answer.customer_value;
                        this.totalSubCustomerAnnually[data.customer_template_category.customer_sub_category.customer_sub_category_name] += (data.answer.customer_value * 12);
                    }
                    else
                    {
                        this.totalCustomerMonthly[data.customer_template_category.customer_category.customer_category_name] += data.answer.admin_value;
                        this.totalCustomerAnnually[data.customer_template_category.customer_category.customer_category_name] += (data.answer.admin_value * 12);

                        // for all sub category
                        this.totalSubCustomerMonthly[data.customer_template_category.customer_sub_category.customer_sub_category_name] += data.answer.admin_value;
                        this.totalSubCustomerAnnually[data.customer_template_category.customer_sub_category.customer_sub_category_name] += (data.answer.admin_value * 12);
                    }
                }
                else
                {
                    // if admin value is null get customer value
                    if(data.answer.admin_value == null)
                    {
                        this.totalCustomerMonthly[data.customer_template_category.customer_category.customer_category_name] += (data.answer.customer_value / 12);
                        this.totalCustomerAnnually[data.customer_template_category.customer_category.customer_category_name] += data.answer.customer_value;

                        // for all sub category
                        this.totalSubCustomerMonthly[data.customer_template_category.customer_sub_category.customer_sub_category_name] += (data.answer.customer_value / 12);
                        this.totalSubCustomerAnnually[data.customer_template_category.customer_sub_category.customer_sub_category_name] += data.answer.customer_value;
                    }
                    else
                    {
                        this.totalCustomerMonthly[data.customer_template_category.customer_category.customer_category_name] += (data.answer.admin_value / 12);
                        this.totalCustomerAnnually[data.customer_template_category.customer_category.customer_category_name] += data.answer.admin_value;

                        // for all sub category
                        this.totalSubCustomerMonthly[data.customer_template_category.customer_sub_category.customer_sub_category_name] += (data.answer.admin_value / 12);
                        this.totalSubCustomerAnnually[data.customer_template_category.customer_sub_category.customer_sub_category_name] += data.answer.admin_value;
                    }
                }
            });
            
            Object.keys(this.totalCustomerMonthly).forEach((key) => {
                // this.totalCustomerAnnually[key] += (this.totalCustomerMonthly[key] * 12);
                this.totalCustomerMonthly[key] = parseFloat(this.totalCustomerMonthly[key].toFixed(2));
            });

            Object.keys(this.totalSubCustomerMonthly).forEach((key) => {
                this.totalSubCustomerMonthly[key] = parseFloat(this.totalSubCustomerMonthly[key].toFixed(2));
            });

            this.monthSeries = Object.values(this.totalCustomerMonthly);
            this.annualSeries = Object.values(this.totalCustomerAnnually);

            this.monthSubSeries = Object.values(this.totalSubCustomerMonthly);

            this.radarMonthSeries[0].data = Object.values(this.totalCustomerMonthly);
            this.radarAnnualSeries[0].data = Object.values(this.totalCustomerAnnually);
            
            this.radarMonthSubSeries[0].data = Object.values(this.totalSubCustomerMonthly);

        },
    }
  }

</script>
<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
/* Background colour of an input field with value */
.otp-input.is-complete {
  background-color: #e4e4e4;
}

.btn:not(:last-child) {
    margin-right: 1.5rem;
}

/* input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
} */




</style>
<style scoped>
@media (min-width: 1200px) {
    .container {
        max-width: 1340px;
    }
}
@media (max-width: 576px) {
    ::v-deep .form-group{
        margin-bottom: 0;
    }
    .otp-input{
        margin:0;
    }
    
    .container-size {
        margin-top: -5.5rem !important;
    }
    .card-sub-body, p {
        margin-bottom: 0;
        padding: 15px 0;
    }

}
@media (max-width: 800px) {
    .btn-size{
        padding: 0.625rem 1.25rem;
        font-size: 0.875rem
    }
}
@media (min-width: 667px) {
    .card-size{
        min-width:50rem;
    }

}
.customer_info .row p{
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0.7rem;
}
</style>