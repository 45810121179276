<template>
    <div>
        <base-header class="pb-6">
            <b-row class="align-items-center py-4">
                <b-col lg="6" cols="7">
                <h6 class="h2 text-white d-inline-block mb-0">KYC Form</h6>
                <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                    <route-breadcrumb></route-breadcrumb>
                </nav>
                </b-col>
            </b-row>
        </base-header>
        <b-container fluid class="mt--6">
            <b-row align-v="center" class="justify-content-center">
                <!-- <b-col md="auto"> -->
                <b-card style="width: 30rem; text-align: center;">
                    <h3>WELCOME</h3>
                    <div class="w-100 p-4 d-flex align-items-center justify-content-center">
                        <base-button type="primary"  @click="onSubmit()">Proceed to Form</base-button>
                    </div>
                </b-card>
                <!-- </b-col> -->
            </b-row>
                            
        </b-container>
    </div>
</template>
<script>
    import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
    import axios from 'axios'
    //import loginFunctionMix from '@/assets/js/loginFunctionMix.js'
    import { mapWritableState ,mapStores, mapActions, mapState } from 'pinia'
    import { useLoginInfoStore } from '@/stores/login_info'

    export default {
        
        data() {
            return {
                customer_id: 1,
                template_id: 1,
            };
        },
        methods: {
            ...mapActions(useLoginInfoStore, ['fetchLoginInformation']),
            onSubmit() {
                const authUser = {}
                const loginUser = {}
                const defaultDashboard = {}

                const postData = {
                grant_type : 'password',
                client_id : clientId,
                client_secret : clientSecret,
                username : 'admin@fintos.tech',
                password : 'doink@007D',
                scope : '*'
                }

                axios.post(LoginURL, postData )
                .then ( response => {
                    if (response.status === 200)
                    {
                        authUser.access_token = response.data.access_token
                        authUser.refresh_token = response.data.refresh_token
                        window.localStorage.setItem('authUser', JSON.stringify(authUser))

                        axios.get(apiDomain + 'setup/user.info', { headers: getHeader() })
                        .then(response => {
                            // console.log(response)
                            loginUser.id = response.data.id
                            loginUser.role_id = response.data.role_id
                            loginUser.name = response.data.name
                            loginUser.email = response.data.email
                            window.localStorage.setItem('loginUser', JSON.stringify(loginUser))
                            this.fetchLoginInformation()
                            //this.getLoginUserRoleAccess()

                            var customerData = JSON.parse(window.localStorage.getItem('customerData'));
                            if(customerData === null)
                            {
                                this.$router.push({path: '/kyc_forms/' + this.customer_id + '/' + this.customer_form_id})
                            }
                            else
                            {
                                this.$router.push({path: '/kyc_forms/' + customerData.customer_id + '/' + customerData.customer_form_id})
                            }
                            
                        })
                    }
                })
                .catch ( error => {
                    console.log(error.response.status)
                    this.model.status = "Invalid Email or Password !!!"
                } );
            },
        }
    }
</script>