<template>
  <b-card no-body>
    <b-card-header class="border-0">
      <b-row >
        <b-col cols="6">
          <h3 class="mb-0">New KYC
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
          </h3>
        </b-col>
      </b-row>
    </b-card-header>
    <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between">
      <el-select
        class="select-primary pagination-select p-2"
        v-model="pagination.perPage"
        placeholder="Per page"
      >
        <el-option
          class="select-primary"
          v-for="item in pagination.perPageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      
      <div class="p-2">
        <base-input v-model="searchQuery"
          v-on:input="debouncedHandler"
                    prepend-icon="fas fa-search"
                    placeholder="Search...(case-sensitive)">
        </base-input>
      </div>
    </b-col>
    <b-tabs
    active-nav-item-class="font-weight-bold text-primary"
    active-tab-class="font-weight-bold text-success"
    content-class="mt-3"
    >
      <b-tab title="IM2U Customer" @click="im2uCustomerList()">
        <el-table class="table-responsive align-items-center table-flush"
                  header-row-class-name="thead-light"
                  :data="queriedData"
                  @sort-change="sortChange"
                  @selection-change="selectionChange"
                  v-show="im2uCustomer"
                  row-key="id">
          <el-table-column label="Id"
                          min-width="100px"
                          prop="id"
                          sortable>
            <template v-slot="{row}">
              <b>{{row.id}}</b>
            </template>
          </el-table-column>

          <el-table-column label="Name"
                          prop="customer_name"
                          min-width="150px"
                          sortable>
            <template v-slot="{row}">
              <b>{{row.customer_name}}</b>
            </template>
          </el-table-column>

          <el-table-column label="NRIC"
                          prop="nric"
                          min-width="150px"
                          sortable>
            <template v-slot="{row}">
              <b>{{row.nric}}</b>
            </template>
          </el-table-column>

          <el-table-column label="Email"
                          prop="email_address"
                          min-width="300px"
                          sortable>
            <template v-slot="{row}">
              <b>{{row.email_address}}</b>
            </template>
          </el-table-column>

          <el-table-column label="Contact"
                          prop="contact_number"
                          min-width="150px"
                          sortable>
            <template v-slot="{row}">
              <b>{{row.contact_number}}</b>
            </template>
          </el-table-column>

          <!-- <el-table-column label="From"
                          prop="external_application_name"
                          min-width="150px"
                          sortable>
            <template v-slot="{row}">
              <b>{{row.external_application.external_application_name}}</b>
            </template>
          </el-table-column> -->

          <!-- <el-table-column label="External Customer Id"
                          prop="external_customer_id"
                          min-width="150px"
                          sortable>
            <template v-slot="{row}">
              <b>{{row.external_customer_id}}</b>
            </template>
          </el-table-column> -->

          <!-- <el-table-column label="Created By"
                          min-width="150px"
                          prop="created_by"
                          sortable>
            <template v-slot="{row}">
              <b>{{row.created_by.name}}</b>
            </template>
          </el-table-column> -->

          <el-table-column min-width="180px" align="center">
            <div slot-scope="{row}" class="table-actions">
              <base-button type="primary" size="sm" @click.prevent="onSelectCustomer(row, 'im2u')">
                <!-- <span class="btn-inner--icon"><i class="fas fa-edit"></i></span> -->
                <span class="btn-inner--text">Select Customer</span>
              </base-button>
            </div>
          </el-table-column>
        </el-table>
      </b-tab>
      <b-tab title="IM2U V2 Customer" @click="im2uV2CustomerList()" active>
        <el-table class="table-responsive align-items-center table-flush"
                  header-row-class-name="thead-light"
                  :data="queriedData"
                  @sort-change="sortChange"
                  @selection-change="selectionChange"
                  v-show="im2uCustomer"
                  row-key="id">
          <el-table-column label="Id"
                          min-width="100px"
                          prop="id"
                          sortable>
            <template v-slot="{row}">
              <b>{{row.id}}</b>
            </template>
          </el-table-column>

          <el-table-column label="Name"
                          prop="customer_name"
                          min-width="150px"
                          sortable>
            <template v-slot="{row}">
              <b>{{row.customer_name}}</b>
            </template>
          </el-table-column>

          <el-table-column label="NRIC"
                          prop="nric"
                          min-width="150px"
                          sortable>
            <template v-slot="{row}">
              <b>{{row.nric}}</b>
            </template>
          </el-table-column>

          <el-table-column label="Email"
                          prop="email_address"
                          min-width="300px"
                          sortable>
            <template v-slot="{row}">
              <b>{{row.email_address}}</b>
            </template>
          </el-table-column>

          <el-table-column label="Contact"
                          prop="contact_number"
                          min-width="150px"
                          sortable>
            <template v-slot="{row}">
              <b>{{row.contact_number}}</b>
            </template>
          </el-table-column>
          <el-table-column min-width="180px" align="center">
            <div slot-scope="{row}" class="table-actions">
              <base-button type="primary" size="sm" @click.prevent="onSelectCustomer(row, 'im2uv2')">
                <span class="btn-inner--text">Select Customer</span>
              </base-button>
            </div>
          </el-table-column>
        </el-table>
      </b-tab>
    </b-tabs>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class="">
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

          <span v-if="selectedRows.length">
            &nbsp; &nbsp; {{selectedRows.length}} rows selected
          </span>
        </p>

      </div>
      <base-pagination
        class="pagination-no-border"
        :current="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
        @change="paginationChanged($event)"
      >
      </base-pagination>
    </div>

  </b-card>

</template>
<script>
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Select, Option } from 'element-ui'
  import axios from 'axios'
  import {apiDomain, getHeader} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  import { BasePagination } from '@/components';
  import clientPaginationMixin from '@/assets/js/clientPaginationMixin'
  import debounce from 'lodash.debounce';

  export default {
    name: 'send-customers-form-table',
    mixins: [ErrorFunctionMixin,clientPaginationMixin],
    components: {
      BasePagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Tooltip.name]: Tooltip,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        propsToSearch:[],
        tableData: [],
        currentPage: 1,
        mode: 'listing',
        selectedRows: [],
        searchText: '',
        debouncedHandler: '',
        im2uCustomer: false,
        TableColumn:[{
          type: 'selection'
        }]
      };
    },
    created(){
      this.im2uV2CustomerList()
    },
    mounted(){
      this.debouncedHandler = debounce(() => {
        // this.searchCustomerList();
      }, 500);
    },
    methods: {
      paginationChanged(page) {
        this.pagination.currentPage = page
      },
      selectionChange(selectedRows) {
      this.selectedRows = selectedRows
      },
      addCustomer(){
        this.$router.push({path: '/customer/addCustomers' })
      },
      onSelectCustomer(row, database_name) {
        if(database_name == 'im2u')
        {
          this.$router.push({
            path: '/customer/sendCustomersForm/' + row.id + '/IM2U',
          })
        }
        else if(database_name == 'im2uv2')
        {
          this.$router.push({
            path: '/customer/sendCustomersForm/' + row.id + '/IM2U V2',
          })
        }
        else
        {
          this.$router.push({
            path: '/customer/sendCustomersForm/' + row.Cust_Id + '/DSR Database',
          })
        }
      },
      dsrCustomerList(){
        axios.get(apiDomain + 'customers/customer.get_dsr_customer_list', { headers: getHeader() })
        .then(response => {
          // console.log(response.data.data);
          this.im2uCustomer = false;
          this.propsToSearch = ['Cust_Name'];
          this.tableData = response.data
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Delete Customer") 
        });
        // const str = JSON.stringify(this.tableData, null, 2);
        // console.log(str);
      },
      im2uCustomerList(){
        axios.get(apiDomain + 'customers/customer.get_im2u_customer_list', { headers: getHeader() })
        .then(response => {
          this.im2uCustomer = true;
          this.propsToSearch = ['id','customer_name'];
          this.tableData = response.data
        })
        .catch ( error => {
            this.errorFunction(error, "Delete Customer") 
        });
      },
      im2uV2CustomerList(){
        axios.get(apiDomain + 'customers/customer.get_im2uv2_customer_list', { headers: getHeader() })
        .then(response => {
          this.im2uCustomer = true;
          this.propsToSearch = ['id','customer_name'];
          this.tableData = response.data
        })
        .catch ( error => {
            this.errorFunction(error, "Delete Customer") 
        });
      },
      searchCustomerList()
      {
        const postData = {
          controller_name : "CustomerController",
          search_text : this.searchText,
          table_column: ["customer_name"]
        }

        axios.post(apiDomain + 'customers/data_search', postData  ,{ headers: getHeader() })
        .then ( response => {
          if(response.data.data.length === 0){
            return this.$notify({
              message:
                '<b>Customer : </b> - No Results.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
          }
          if (response.status === 200)
          {
            this.tableData = response.data.data
            console.log(this.tableData)
            this.$notify({
              message:
                '<b>Customer : </b> - Search Successful.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });

          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Customer Listing")
        });
      }

    }
  }
</script>
<style>
.loading {
  text-indent: -9999px;
}
</style>