<template>
  <b-card no-body>
    <b-card-header class="border-0">
      <b-row >
        <b-col cols="6">
          <h3 class="mb-0">Sub Category</h3>
        </b-col>
        <!-- <b-col cols="6" class="text-right">
          <el-tooltip content="Add a new Sub Category" placement="top">
            <base-button type="primary" icon size="sm" @click="addSubCategory()">
              <span class="btn-inner--icon"><i class="fas fa-edit"></i></span>
              <span class="btn-inner--text">Add</span>
            </base-button>

          </el-tooltip>
        </b-col> -->
      </b-row>
    </b-card-header>
    <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between">
      <el-select
        class="select-primary pagination-select p-2"
        v-model="pagination.perPage"
        placeholder="Per page"
      >
        <el-option
          class="select-primary"
          v-for="item in pagination.perPageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <div class="p-2">
        <base-input v-model="searchQuery"
          v-on:input="debouncedHandler"
                    prepend-icon="fas fa-search"
                    placeholder="Search...(case-sensitive)">
        </base-input>
      </div>
    </b-col>
    <el-table class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="queriedData"
              @sort-change="sortChange"
              @selection-change="selectionChange"
              row-key="id">
      <el-table-column label="id"
                       min-width="60px"
                       prop="sub_category_id"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.sub_category_id}}</b>
        </template>
      </el-table-column>

      <el-table-column label="Name"
                       prop="sub_category_name"
                       min-width="150px"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.sub_category_name}}</b>
        </template>
      </el-table-column>

      <el-table-column label="Category"
                       min-width="150px"
                       prop="category_name"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.category.category_name}}</b>
        </template>
      </el-table-column>

      <el-table-column label="Created By"
                       min-width="150px"
                       prop="created_by"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.created_by.name}}</b>
        </template>
      </el-table-column>

      <el-table-column min-width="80px" align="center">
        <div slot-scope="{row}" class="table-actions btn-group-vertical">
          <base-button type="primary" icon size="sm" @click.prevent="onEdit(row)">
            <!-- <span class="btn-inner--icon"><i class="fas fa-edit"></i></span> -->
            <span class="btn-inner--text">Edit</span>
            </base-button>
          <base-button type="primary" icon size="sm" @click.prevent="onDelete(row)">
            <!-- <span class="btn-inner--icon"><i class="fas fa-trash"></i></span> -->
            <span class="btn-inner--text">Delete</span>
          </base-button>
        </div>
      </el-table-column>
    </el-table>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class="">
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

          <span v-if="selectedRows.length">
            &nbsp; &nbsp; {{selectedRows.length}} rows selected
          </span>
        </p>

      </div>
      <base-pagination
        class="pagination-no-border"
        :current="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
        @change="paginationChanged($event)"
      >
      </base-pagination>
    </div>

  </b-card>

</template>
<script>
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Select, Option } from 'element-ui'
  import axios from 'axios'
  import {apiDomain, getHeader} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  import { BasePagination } from '@/components';
  import clientPaginationMixin from '@/assets/js/clientPaginationMixin'
  import debounce from 'lodash.debounce';

  export default {
    name: 'sub-category-table',
    mixins: [ErrorFunctionMixin,clientPaginationMixin],
    components: {
      BasePagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Tooltip.name]: Tooltip,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        propsToSearch:['sub_category_name'],
        tableData: [],
        currentPage: 1,
        mode: 'listing',
        selectedRows: [],
        searchText: '',
        debouncedHandler: '',
        TableColumn:[{
          type: 'selection'
        }]
      };
    },
    created(){
      
    },
    mounted(){
      this.subCategoryList(),
      this.debouncedHandler = debounce(() => {
        // this.searchSubCategoryList();
      }, 500);
    },
    methods: {
      paginationChanged(page) {
        this.pagination.currentPage = page
      },
      selectionChange(selectedRows) {
      this.selectedRows = selectedRows
      },
      onEdit(row) {
        this.$router.push({path: '/setup/editSubCategory/' + row.sub_category_id })
        // alert(`You want to edit ${row.name}`)
      },
      onDelete(row) {
        swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          // showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          // denyButtonText: `Don't save`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            axios.delete(apiDomain + 'setup/sub_category/' + row.sub_category_id,{ headers: getHeader() })
            .then ( response => {
              if (response.status === 200)
              {
                this.$notify({
                  message:
                    '<b>Sub Category : ' + row.sub_category_name + '</b> - Successfully Deleted.',
                  timeout: 10000,
                  icon: 'ni ni-bell-55',
                  type: 'default',
                });
                this.subCategoryList()
              }
            })
            .catch ( error => {
                console.log(error.response.status)
                this.errorFunction(error, "Delete Sub Category")
                
            });
          } 
        })
      },
      addSubCategory(){
        this.$router.push({path: '/setup/addSubCategory' })
      },
      subCategoryList(){
        axios.get(apiDomain + 'setup/sub_category', { headers: getHeader() })
        .then(response => {
          this.tableData = response.data.data
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Delete Sub Category") 
        });
        const str = JSON.stringify(this.tableData, null, 2);
        console.log(str);
      },
      searchSubCategoryList()
      {
        const postData = {
          controller_name : "SubCategoryController",
          search_text : this.searchText,
          table_column: ["sub_category_name"]
        }

        axios.post(apiDomain + 'setup/data_search', postData  ,{ headers: getHeader() })
        .then ( response => {
          if(response.data.data.length === 0){
            return this.$notify({
              message:
                '<b>Sub Category : </b> - No Results.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
          }
          if (response.status === 200)
          {
            this.tableData = response.data.data
            console.log(this.tableData)
            this.$notify({
              message:
                '<b>Sub Category : </b> - Search Successful.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });

          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Sub Category Listing")
        });
      }

    }
  }
</script>
