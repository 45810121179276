<template>
    <div>
        <base-header class="pb-6">
            <b-row class="align-items-center py-4">
                <b-col lg="6" cols="7">
                <!-- <h6 class="h2 text-white d-inline-block mb-0">KYC Form</h6>
                <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                    <route-breadcrumb></route-breadcrumb>
                </nav> -->
                </b-col>
                <b-col lg="6" cols="7" class="text-right">
                    <!-- <base-button size="sm" type="primary" @click="close()">Close</base-button> -->
                    <!-- <base-button size="sm" type="neutral">Filters</base-button> -->
                </b-col>
            </b-row>
        </base-header>

        <b-container id="print" fluid class="mt--6">
            <b-row align-v="center" class="justify-content-center" >
                <b-card style="width: 80%; "> 
                    <div v-if="uniqueSubCategories.length != 0">
                        <b-row>
                            <b-col md="6" cols="6">
                                <h1>{{customerAnswerDatas[0].customer_form.customer_form_name}} V3</h1>
                            </b-col>
                            <b-col md="6" class="text-right button-right">
                                <base-button size="sm" type="primary" @click="exportForm(customerAnswerDatas[0].customer_form.customer_form_name, 'Excel')">Export to Excel</base-button>
                                <base-button size="sm" type="primary" @click="exportForm(customerAnswerDatas[0].customer_form.customer_form_name, 'PDF')">Export to PDF</base-button>
                                <base-button size="sm" type="primary" @click="close()">Close</base-button>
                            </b-col>
                            
                        </b-row>
                        <h5 class="text-danger"> Note : <br>
                            <i>
                                1. The blue color value indicated the value filled by customer.
                            </i>
                            <br>
                            <i>
                                2. The gray color value indicated the value auto calculated by system.
                            </i>
                            <br>
                            <i>
                                3. If the monthly column is being filled up, the annual value will be auto calculated; Vice versa.
                            </i>
                            <br>
                            <i>
                                4. Advisory can only edit the column filled up by customer, if monthly column is filled up, advisory can only update the monthly column.
                            </i>
                            <br>
                        </h5>
                        <br> 
                        <b-tabs active-nav-item-class="font-weight-bold text-primary"
                        content-class="mt-3"
                        >
                            <b-tab v-for="uniqueCategory in uniqueCategories" active :title="uniqueCategory" :key="uniqueCategory" @click="customerAnswerList(uniqueCategory)">       
                                <table style="margin-bottom:20px" v-for="uniqueSubCategory in uniqueSubCategories" :key="uniqueSubCategory">
                                    <tr class="text-white">
                                        <th>{{uniqueSubCategory}}</th>
                                        <th colspan="2">Customer Answer (RM)</th>
                                        <th colspan="2">Advisory Answer (RM)</th>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Monthly</td>
                                        <td>Annually</td>
                                        <td style="width:50px">Monthly</td>
                                        <td style="width:50px">Annually</td>
                                    </tr>
                                    <tr v-for="customerAnswer in customerAnswers" :key="customerAnswer.customer_question_id">
                                        <td v-if="customerAnswer.customer_template_category.customer_sub_category.customer_sub_category_name == uniqueSubCategory">{{customerAnswer.customer_question_column}}</td>
                                        <template v-if="customerAnswer.customer_template_category.customer_sub_category.customer_sub_category_name == uniqueSubCategory">
                                            <template v-if="customerAnswer.frequency.frequency_type == 2 && customerAnswer.answer.customer_value !== null">
                                                <td class="text-blue">{{customerAnswer.answer.customer_value.toLocaleString(undefined, {minimumFractionDigits: 2})}}</td>
                                                <td>{{customerAnswer.answer.customer_annual_value.toLocaleString(undefined, {minimumFractionDigits: 2})}}</td>
                                                <!-- {{totalCustomerMonthly[uniqueSubCategory] += parseFloat(customerAnswer.answer.customer_value.toFixed(2))}} -->
                                            </template>
                                            <template v-else-if="customerAnswer.frequency.frequency_id == null">
                                                <td class="text-blue">{{customerAnswer.answer.customer_value}}</td>
                                                <td>-</td>
                                            </template>
                                            <template v-else-if="customerAnswer.frequency.frequency_type == 1 && customerAnswer.answer.customer_value !== null">
                                                <td>{{customerAnswer.answer.customer_month_value.toLocaleString(undefined, {minimumFractionDigits: 2})}}</td>
                                                <td class="text-blue">{{customerAnswer.answer.customer_value.toLocaleString(undefined, {minimumFractionDigits: 2})}}</td>
                                            </template>
                                            <template v-else>
                                                <td>-</td>
                                                <td>-</td>
                                            </template>
                                        </template>
                                        <template v-if="customerAnswer.customer_template_category.customer_sub_category.customer_sub_category_name == uniqueSubCategory">                                
                                            <template v-if="customerAnswer.frequency.frequency_type == 2 && customerAnswer.answer.answer_id != null">
                                                <td>
                                                    <el-input size="small" id="admin-value" v-on:input="debounceInput(customerAnswer.answer.answer_id, customerAnswer.answer.admin_value)" v-model="customerAnswer.answer.admin_value"></el-input>
                                                </td>
                                                <!-- <td>-</td> -->
                                                <td class="text-gray">{{adminAnnualValue[uniqueSubCategory][customerAnswer.answer.answer_id].toLocaleString(undefined, {minimumFractionDigits: 2})}}</td>
                                            </template>
                                            <template v-else-if="customerAnswer.frequency.frequency_type == 1 && customerAnswer.answer.answer_id != null">
                                                <td class="text-gray">{{adminMonthValue[uniqueSubCategory][customerAnswer.answer.answer_id].toLocaleString(undefined, {minimumFractionDigits: 2})}}</td>
                                                <!-- <td>-</td> -->
                                                <td>
                                                    <el-input size="small" id="admin-value" v-on:input="debounceInput(customerAnswer.answer.answer_id, customerAnswer.answer.admin_value)" v-model="customerAnswer.answer.admin_value"></el-input>
                                                </td>
                                            </template>
                                            <template v-else>
                                                <td>-</td>
                                                <td>-</td>
                                            </template>
                                        </template>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td>{{totalCustomerMonthly[uniqueSubCategory].toLocaleString(undefined, {minimumFractionDigits: 2})}}</td>
                                        <td>{{totalCustomerAnnually[uniqueSubCategory].toLocaleString(undefined, {minimumFractionDigits: 2})}}</td>
                                        <td>{{totalAdminMonthly[uniqueSubCategory].toLocaleString(undefined, {minimumFractionDigits: 2})}}</td>
                                        <td>{{totalAdminAnnually[uniqueSubCategory].toLocaleString(undefined, {minimumFractionDigits: 2})}}</td>
                                    </tr>
                                </table>
                                <b-col>
                                    <h3>Total Yearly Commitment : RM {{totalCustomerCommitment.toLocaleString(undefined, {minimumFractionDigits: 2})}}</h3>
                                    <h3>Average Monthly Commitment : RM {{customerMonthlyCommitment.toLocaleString(undefined, {minimumFractionDigits: 2})}}</h3>
                                </b-col>
                            </b-tab>
                        </b-tabs>
                    </div>
                    <div v-else class="text-center">
                        This user has not answered any questions yet.
                    </div>
                    <br>
                    <b-col class="text-center">
                        <base-button type="primary" icon size="sm" @click.prevent="close()">Close</base-button>
                    </b-col>
                </b-card>
            </b-row>

        </b-container>
        <b-container id="capture">
            <apexchart type="pie" width="400" :options="chartOptions" :series="monthSeries"></apexchart>
        </b-container>

    </div>
</template>
<script>
    import axios from 'axios'
    import {apiDomain, getHeader} from '@/assets/js/config.js'
    import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
    import swal from 'sweetalert2';
    import 'sweetalert2/dist/sweetalert2.css';
    import debounce from 'lodash.debounce';
    import VueApexCharts from 'vue-apexcharts';
    import html2canvas from 'html2canvas';

    export default {
        mixins: [ErrorFunctionMixin],
        components: {
            "apexchart": VueApexCharts,
        },
        data() {
            return {
                customerAnswerDatas: [],
                uniqueCategories: [],
                uniqueSubCategories: [],
                adminAnswer: [],
                debouncedHandler: null,
                totalCustomerMonthly: [],
                totalCustomerAnnually: [],
                totalCustomerCommitment: null,
                totalCategoryCommitment: [],
                customerMonthlyCommitment: null,
                adminMonthValue: [],
                adminAnnualValue: [],
                totalAdminMonthly: [],
                totalAdminAnnually: [],
                monthSeries: [],
                chartOptions: {
                    chart: {
                        type: 'pie',
                    },
                    responsive: [
                        {
                            breakpoint: 500,
                            options: {
                                chart: {
                                    width: 350
                                },
                                legend: {
                                    position: 'bottom'
                                }
                            }
                        },
                        {
                            breakpoint: 1000,
                            options: {
                                chart: {
                                    width: 400
                                },
                                legend: {
                                    position: 'right'
                                }
                            }
                        },
                        {
                            breakpoint: 2000,
                            options: {
                                chart: {
                                    width: 500
                                },
                                legend: {
                                    position: 'right'
                                }
                            }
                        },
                    
                    ],
                    labels: [],
                },
            };
        },
        created() {
            this.customerAnswerList();
        },
        computed:{
            customerAnswers() {
                const {customerAnswerDatas} = this
                return customerAnswerDatas
            }
        },
        watch: {
            customerAnswers: {
                handler: function(val) {
                    let vm = this;
                    let adminMonthValue = this.adminMonthValue
                    let adminAnnualValue = this.adminAnnualValue
                    let totalAdminMonthValue = this.totalAdminMonthly;
                    let totalAdminAnnualValue = this.totalAdminAnnually;
                    /** 
                     * set adminAnnualValue category as first key and question id as second key for auto calculate admin annual value
                     * if the frequency is monthly, means customer answer monthly, therefore need auto calculate for annual value
                    */
                    val.filter( function( data, idx ) {
                        if(data.frequency.frequency_type == 2)
                        {
                            adminMonthValue[data.customer_template_category.customer_sub_category.customer_sub_category_name] = {
                                ...adminMonthValue[data.customer_template_category.customer_sub_category.customer_sub_category_name],
                                [data.answer.answer_id]: parseFloat(data.answer.admin_value)
                            };
                            adminAnnualValue[data.customer_template_category.customer_sub_category.customer_sub_category_name] = {
                                ...adminAnnualValue[data.customer_template_category.customer_sub_category.customer_sub_category_name],
                                [data.answer.answer_id]: parseFloat((data.answer.admin_value * 12).toFixed(2))
                            };
                            
                        }
                        else
                        {
                            adminMonthValue[data.customer_template_category.customer_sub_category.customer_sub_category_name] = {
                                ...adminMonthValue[data.customer_template_category.customer_sub_category.customer_sub_category_name],
                                [data.answer.answer_id]: parseFloat((data.answer.admin_value / 12).toFixed(2))
                            };
                            adminAnnualValue[data.customer_template_category.customer_sub_category.customer_sub_category_name] = {
                                ...adminAnnualValue[data.customer_template_category.customer_sub_category.customer_sub_category_name],
                                [data.answer.answer_id]: parseFloat(data.answer.admin_value)
                            };
                                                    
                        }
                        totalAdminMonthValue[data.customer_template_category.customer_sub_category.customer_sub_category_name] = 0;
                        totalAdminAnnualValue[data.customer_template_category.customer_sub_category.customer_sub_category_name] = 0;
                    });
                    
                    // calculate total admin month value and total admin annual value
                    Object.values(this.uniqueSubCategories).forEach((key) => {
                        Object.values(adminMonthValue[key]).forEach((data) => {
                            if(isNaN(data))
                            {
                               data = 0;
                            }
                            totalAdminMonthValue[key] += data;
                        });
                    });

                    Object.values(this.uniqueSubCategories).forEach((key) => {
                        Object.values(adminAnnualValue[key]).forEach((data) => {
                            if(isNaN(data))
                            {
                               data = 0;
                            }
                            totalAdminAnnualValue[key] += data;
                        });
                    });

                    this.calculateCustomerTotalCommitmentByAdminValue();

                },
                deep: true,
            }
        },
        methods: {
            close() {
                window.close();
            }, 
            home(){
                this.$router.push({path: '/customer/our_customer' });
            },
            customerAnswerList(category_name = null) {
                axios.get(apiDomain + 'customers/customer_question.show_by_form/' + this.$route.params.customer_form_id, { headers: getHeader() })
                .then(response => {
                    let uniqueCategory= [];
                    let uniqueSubCategory= [];
                    let chartColors = [];

                    this.customerAnswerDatas = response.data.data;
                    // console.log(this.customerAnswerDatas);
                    Object.values(this.customerAnswerDatas).forEach((data) => {
                        // check if the question is monthly or annually
                        if(data.frequency.frequency_type == 2)
                        {
                            if(data.answer.admin_value == null)
                            {
                                data.answer = {
                                    ...data.answer,
                                    admin_value: data.answer.customer_value,
                                }
                            }
                            data.answer = {
                                ...data.answer,
                                customer_month_value: data.answer.customer_value,
                                customer_annual_value: parseFloat((data.answer.customer_value * 12).toFixed(2))
                            }
                        }
                        else 
                        {
                            if(data.answer.admin_value == null)
                            {
                                data.answer = {
                                    ...data.answer,
                                    admin_value: data.answer.customer_value,
                                }
                            }
                            data.answer = {
                                ...data.answer,
                                customer_month_value: parseFloat((data.answer.customer_value / 12).toFixed(2)),
                                customer_annual_value: data.answer.customer_value,
                            }
                        }

                        // set category
                        if(uniqueCategory.indexOf(data.customer_template_category.customer_category.customer_category_name) < 0)
                        {
                            uniqueCategory[data.customer_template_category.customer_category_sort] = data.customer_template_category.customer_category.customer_category_name;
                            // set chart color and initialize total category commitment based on each category
                            chartColors.push('#' + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0'));
                            this.totalCategoryCommitment[data.customer_template_category.customer_category.customer_category_name] = 0;
                        }

                        // set sub category
                        if(category_name == null)
                        {
                            if(uniqueSubCategory.indexOf(data.customer_template_category.customer_sub_category.customer_sub_category_name) < 0)
                            {
                                uniqueSubCategory[data.customer_template_category.customer_sub_category_sort] = data.customer_template_category.customer_sub_category.customer_sub_category_name;
                                this.totalCustomerMonthly[data.customer_template_category.customer_sub_category.customer_sub_category_name] = 0;
                                this.totalCustomerAnnually[data.customer_template_category.customer_sub_category.customer_sub_category_name] = 0;
                            }
                        }
                        else
                        {
                            if(uniqueSubCategory.indexOf(data.customer_template_category.customer_sub_category.customer_sub_category_name) < 0)
                            {
                                if(category_name == data.customer_template_category.customer_category.customer_category_name)
                                uniqueSubCategory[data.customer_template_category.customer_sub_category_sort] = data.customer_template_category.customer_sub_category.customer_sub_category_name;
                                this.totalCustomerMonthly[data.customer_template_category.customer_sub_category.customer_sub_category_name] = 0;
                                this.totalCustomerAnnually[data.customer_template_category.customer_sub_category.customer_sub_category_name] = 0;
                            }
                        }
                        
                    })
                    this.uniqueCategories = uniqueCategory;
                    this.uniqueSubCategories = uniqueSubCategory;

                    this.chartOptions = {
                        ...this.chartOptions,
                        colors: chartColors,
                        labels: uniqueCategory,
                    };

                    this.calculateCustomerTotalCommitment();
                    
                })
                .catch ( error => {
                    console.log(error.response.status)
                    this.errorFunction(error, "Error Getting Customer Question") 
                });
            },
            debounceInput: debounce(function (answer_id, admin_value) {
                const postData = {
                    admin_value : admin_value,
                }
                // console.log(postData);
                axios.put(apiDomain + 'customers/answer/' + answer_id, postData, { headers: getHeader() })
                .then(response => {
                })
                .catch ( error => {
                    console.log(error.response.status)
                    this.errorFunction(error, "Error Saving Admin Value") 
                });
            }, 300),
            exportForm(customer_form_name, export_format){
                if(export_format == 'Excel')
                {
                    axios.get(apiDomain + 'customers/customer_question.export_form/' + this.$route.params.customer_form_id + '/' + export_format, {responseType: 'arraybuffer', headers: getHeader()})
                    .then(response => {
                        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        let fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', customer_form_name+'.xlsx');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    })
                }
                else
                {
                    // post the image to API
                    html2canvas(document.querySelector("#capture")).then(canvas => {
                        var image = canvas.toDataURL("image/jpeg");

                        // testing save chart as image
                        // var postData = {
                        //     image: image
                        // };
                        // axios.post(apiDomain + 'customers/customer_question.save_chart', postData, {headers: getHeader()})
                        // .then(function (response) {
                        //     console.log(response);
                        // })
                        // .catch(function (error) {
                        //     console.log(error);
                        // });

                        var exportData = {
                            customer_form_id: this.$route.params.customer_form_id,
                            export_format: export_format,
                            image: image
                        }

                        axios.post(apiDomain + 'customers/customer_question.export_form', exportData, {responseType: 'blob', headers: getHeader()})
                        .then((response) => {
                            var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                            var fileLink = document.createElement('a');
                            fileLink.href = fileURL;
                            fileLink.setAttribute('download', customer_form_name+'.pdf');
                            document.body.appendChild(fileLink);
                            fileLink.click();
                        });
                    });
                    
                    // axios get for export file
                    // axios.get(apiDomain + 'customers/customer_question.export_form/' + this.$route.params.customer_form_id + '/' + export_format, {responseType: 'blob', headers: getHeader()})
                    // .then((response) => {
                    //     var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                    //     var fileLink = document.createElement('a');
                    //     fileLink.href = fileURL;
                    //     fileLink.setAttribute('download', customer_form_name+'.pdf');
                    //     document.body.appendChild(fileLink);
                    //     fileLink.click();
                    // });
                }
                
            },
            calculateCustomerTotalCommitment() {
                Object.values(this.customerAnswerDatas).forEach((data) => {
                    this.totalCustomerMonthly[data.customer_template_category.customer_sub_category.customer_sub_category_name] += data.answer.customer_month_value;
                    this.totalCustomerAnnually[data.customer_template_category.customer_sub_category.customer_sub_category_name] += data.answer.customer_annual_value;
                    // calculate total month commitment for each category
                    this.totalCategoryCommitment[data.customer_template_category.customer_category.customer_category_name] += data.answer.customer_month_value;
                });

                var monthlySum = 0;
                for (let commitment of Object.values(this.totalCustomerMonthly)) {
                    monthlySum += commitment;
                }

                var annuallySum = 0;
                for (let commitment of Object.values(this.totalCustomerAnnually)) {
                    annuallySum += commitment;
                }

                this.totalCustomerCommitment = annuallySum;
                this.customerMonthlyCommitment = monthlySum;
                this.monthSeries = Object.values(this.totalCategoryCommitment);
            },
            calculateCustomerTotalCommitmentByAdminValue() {
                var monthlySum = 0;
                
                for (let commitment of Object.values(this.totalAdminMonthly)) {
                    monthlySum += commitment;
                }

                var annuallySum = 0;
                for (let commitment of Object.values(this.totalAdminAnnually)) {
                    annuallySum += commitment;
                }

                this.totalCustomerCommitment = annuallySum;
                this.customerMonthlyCommitment = monthlySum;

            }
        }
    };
</script>
<style scoped>
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td , th {
    border: 1px solid #dddddd;
    text-align: center;
    /* height: 10px; */
    /* width: 20%; */
}

td:first-child {
    text-align: left;
    padding-left: 8px;
}

th {
    background-color: #525f7f;
    font-size: x-large;
    width: 20%;
}

.button-right {
    padding-right: 0;
}

</style>
<style>
#admin-value {
    text-align:center;
    font-size:medium;
}
</style>