var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"border-0"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('h3',{staticClass:"mb-0"},[_vm._v("New KYC         ")])])],1)],1),_c('b-col',{staticClass:"d-flex justify-content-center justify-content-sm-between",attrs:{"cols":"12"}},[_c('el-select',{staticClass:"select-primary pagination-select p-2",attrs:{"placeholder":"Per page"},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1),_c('div',{staticClass:"p-2"},[_c('base-input',{attrs:{"prepend-icon":"fas fa-search","placeholder":"Search...(case-sensitive)"},on:{"input":_vm.debouncedHandler},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('b-tabs',{attrs:{"active-nav-item-class":"font-weight-bold text-primary","active-tab-class":"font-weight-bold text-success","content-class":"mt-3"}},[_c('b-tab',{attrs:{"title":"IM2U Customer"},on:{"click":function($event){return _vm.im2uCustomerList()}}},[_c('el-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.im2uCustomer),expression:"im2uCustomer"}],staticClass:"table-responsive align-items-center table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.queriedData,"row-key":"id"},on:{"sort-change":_vm.sortChange,"selection-change":_vm.selectionChange}},[_c('el-table-column',{attrs:{"label":"Id","min-width":"100px","prop":"id","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.id))])]}}])}),_c('el-table-column',{attrs:{"label":"Name","prop":"customer_name","min-width":"150px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.customer_name))])]}}])}),_c('el-table-column',{attrs:{"label":"NRIC","prop":"nric","min-width":"150px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.nric))])]}}])}),_c('el-table-column',{attrs:{"label":"Email","prop":"email_address","min-width":"300px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.email_address))])]}}])}),_c('el-table-column',{attrs:{"label":"Contact","prop":"contact_number","min-width":"150px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.contact_number))])]}}])}),_c('el-table-column',{attrs:{"min-width":"180px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"table-actions"},[_c('base-button',{attrs:{"type":"primary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.onSelectCustomer(row, 'im2u')}}},[_c('span',{staticClass:"btn-inner--text"},[_vm._v("Select Customer")])])],1)}}])})],1)],1),_c('b-tab',{attrs:{"title":"IM2U V2 Customer","active":""},on:{"click":function($event){return _vm.im2uV2CustomerList()}}},[_c('el-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.im2uCustomer),expression:"im2uCustomer"}],staticClass:"table-responsive align-items-center table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.queriedData,"row-key":"id"},on:{"sort-change":_vm.sortChange,"selection-change":_vm.selectionChange}},[_c('el-table-column',{attrs:{"label":"Id","min-width":"100px","prop":"id","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.id))])]}}])}),_c('el-table-column',{attrs:{"label":"Name","prop":"customer_name","min-width":"150px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.customer_name))])]}}])}),_c('el-table-column',{attrs:{"label":"NRIC","prop":"nric","min-width":"150px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.nric))])]}}])}),_c('el-table-column',{attrs:{"label":"Email","prop":"email_address","min-width":"300px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.email_address))])]}}])}),_c('el-table-column',{attrs:{"label":"Contact","prop":"contact_number","min-width":"150px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.contact_number))])]}}])}),_c('el-table-column',{attrs:{"min-width":"180px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"table-actions"},[_c('base-button',{attrs:{"type":"primary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.onSelectCustomer(row, 'im2uv2')}}},[_c('span',{staticClass:"btn-inner--text"},[_vm._v("Select Customer")])])],1)}}])})],1)],1)],1),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v(" Showing "+_vm._s(_vm.from + 1)+" to "+_vm._s(_vm.to)+" of "+_vm._s(_vm.total)+" entries "),(_vm.selectedRows.length)?_c('span',[_vm._v("     "+_vm._s(_vm.selectedRows.length)+" rows selected ")]):_vm._e()])]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"current":_vm.pagination.currentPage,"per-page":_vm.pagination.perPage,"total":_vm.total},on:{"change":function($event){return _vm.paginationChanged($event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }