<template>
    <div>
        <base-header class="pb-6">
            <b-row class="align-items-center py-4">
                <!-- <b-col lg="6" cols="7">
                <h6 class="h2 text-white d-inline-block mb-0">KYC Form</h6>
                <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                    <route-breadcrumb></route-breadcrumb>
                </nav>
                </b-col> -->
            </b-row>
        </base-header>

        <b-container class="mt--6">
            <b-row class="justify-content-center" >
                <b-card style="width: 50rem; text-align: center;"> 
                    <b-col>
                        <h3>Congratulations. You have finished all the questions. Thank you for taking your time.</h3> 
                    </b-col>
                    <br>
                    <b-row class="justify-content-center">
                        <base-button type="primary" icon size="sm" @click.prevent="close()">Close</base-button>
                        <base-button type="primary" icon size="sm" @click.prevent="answer()">Reanswer</base-button>
                    </b-row>
                </b-card>
            </b-row>
            <b-row class="justify-content-center">
                <div id="chart">
                    <apexchart type="pie" width="380" :options="chartOptions" :series="series"></apexchart>
                </div>
            </b-row>
            
        </b-container>

    </div>
</template>
<script>
  import VueApexCharts from 'vue-apexcharts';
  export default {
    props : [ "customerId", "customerFormId" ],
    data() {
      return {
        series: [44, 55, 13, 43, 22, 10, 28],
        chartOptions: {
            chart: {
                width: 380,
                type: 'pie',
            },
            labels: ['Team A', 'Team Bgds', 'Team C', 'Team D', 'Team Edfsafds'],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 380
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
      };
    },
    components: {
      "apexchart": VueApexCharts,
    },
    methods: {
        home(){
            this.$router.push({path: '/templates/global_template' });
        },
        close() {
            window.close();
        },
        answer() {
            this.series= [Math.random() * 10, Math.random() * 10]
            this.chartOptions = {
                labels: [Math.random() * 10, Math.random() * 10],
            };
        }
    }
  };
</script>