<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Sub Category</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </b-col>
        <!--
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
        -->
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Edit Sub Category</h3>
              <!-- Card body -->
              <form>
                <!-- Input groups with icon -->
                <b-row>
                  <b-col md="6">
                    <base-input  label="Sub Category Name" placeholder="Sub Category name" v-model="sub_category.sub_category_name"></base-input>
                  </b-col>
                  <b-col md="6">
                    <base-input label="Category">
                      <el-select v-model="selects.simple" filterable  placeholder="Select Category">
                        <!-- <el-option Selected disabled value="x">-- Please Select Sub Category(s) as HOD --</el-option> -->
                        <el-option v-for="option in selectOptions" :key="option.category_name" :label="option.category_name" v-bind:value="option.category_id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                </b-row>

                <base-button type="primary" @click="updateData()">Create / Save</base-button>
                <base-button type="primary" @click="$router.back()">Cancel</base-button>
              </form>
            </card>

          </div>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
  import { Select , Option } from 'element-ui'
  import axios from 'axios'
  import {apiDomain, getHeader} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components:{
      [Select.name]: Select,
      [Option.name]: Option,
    },   
    data() {
      return {
        sub_category: {
          sub_category_name: null,
        },
        selects : {
          simple: []
        },
        selectOptions: []

      }
    },
    created(){
        this.categoryList()
        axios.get(apiDomain + 'setup/sub_category/' + this.$route.params.id ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.sub_category.sub_category_name = response.data.data[0].sub_category_name
            this.selects.simple = response.data.data[0].category.category_id
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Show Sub Category")
            
        });
          const str = JSON.stringify(this.selects.simple);
          console.log(str);
          console.table(this.selects.simple);
    },
    methods: {
      updateData(){
          const postData = {
            sub_category_name : this.sub_category.sub_category_name,
            category_id  : this.selects.simple,
          }
          
          const str = JSON.stringify(postData);
          console.log(str);
          console.table(postData);

          axios.put(apiDomain + 'setup/sub_category/' + this.$route.params.id , postData ,{ headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              this.$notify({
                message:
                  '<b>Edit Sub Category : ' + this.sub_category.sub_category_name + '</b> - Successfully Saved.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });
              this.$router.back()
            }
          })
          .catch ( error => {
              console.log(error.response.status)
              this.errorFunction(error, "Add Sub Category")
              
          });

      },
      categoryList(){
        axios.get(apiDomain + 'setup/category', { headers: getHeader() })
        .then(response => {
            this.selectOptions = response.data.data
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Add Sub Category")
        });
      }
    }
  }
</script>
