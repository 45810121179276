<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Template</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </b-col>
        <!--
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
        -->
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input global_templates -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Edit Template </h3>
              <!-- Card body -->
              <form>

                <b-row>
                  <b-col md="6">
                    <base-input  label="Template Name" placeholder="Template Name" v-model="global_template.global_template_name"></base-input>
                  </b-col>
                  <!-- <b-col md="6">
                    <base-input  label="Global Template Description" placeholder="Global Template Description" v-model="global_template.global_template_description"></base-input>
                  </b-col> -->
                  <!-- <b-col md="6">
                    <base-input>
                      <el-select v-model="selects.simple" filterable  placeholder="Select Document Type" disabled>
                        <el-option Selected disabled value="x">-- Please Select User(s) as HOD --</el-option>
                        <el-option v-for="option in selectOptions" :key="option.sub_name" :label="option.sub_name" v-bind:value="option.sub_id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col> -->
                </b-row>

                <base-button type="primary" @click="updateData()">Create / Save</base-button>
                <base-button type="primary" @click="$router.back()">Cancel</base-button>
              </form>
            </card>

          </div>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
  import { Select , Option } from 'element-ui'
  import axios from 'axios'
  import {apiDomain, getHeader} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components:{
      [Select.name]: Select,
      [Option.name]: Option,
    },   
    data() {
      return {
        global_template: {
            global_template_name: null,
            global_template_description: null,
        },
        sub:{
            sub_id: null,
            sub_name: null
        },
        selects : {
          simple: []
        },
        selectOptions: []

      }
    },
    created(){
        axios.get(apiDomain + 'templates/global_template/' + this.$route.params.id ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.global_template.global_template_name = response.data.data[0].global_template_name
            this.global_template.global_template_description  = response.data.data[0].global_template_description
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Show Template")
            
        });
    },
    methods: {
      updateData(){
          const postData = {
            global_template_name : this.global_template.global_template_name,
            global_template_description : this.global_template.global_template_description,
          }
          
          const str = JSON.stringify(postData);
          console.log(str);
          console.table(postData);

          axios.put(apiDomain + 'templates/global_template/' + this.$route.params.id , postData ,{ headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              this.$notify({
                message:
                  '<b>Edit Template : ' + this.global_template.global_template_name + '</b> - Successfully Saved.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });
              this.$router.back()
            }
          })
          .catch ( error => {
              console.log(error.response.status)
              this.errorFunction(error, "Edit Template")
              
          });

      },
    }
  }
</script>
