<template>
  <b-card>
    <base-button icon type="primary">
      <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
      <span class="btn-inner--text">New Template Category</span>
    </base-button>
  
    <div class="form-group" v-for="(input,k) in inputs" :key="k">
      <b-row>
        <b-col md="6">
          <base-input label="Category">
            <el-select v-model="selects.category" filterable placeholder="Select Category">
              <el-option Selected disabled value="x">-- Please Select Category --</el-option>
              <el-option v-for="option in selectCategoryOptions" :key="option.category_name" :label="option.category_name" v-bind:value="option.category_id">
              </el-option>
            </el-select>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Sub Category">
            <el-select v-model="selects.sub_category" filterable placeholder="Select Sub Category">
              <el-option Selected disabled value="x">-- Please Select Sub Category --</el-option>
              <el-option v-for="option in selectSubCategoryOptions" :key="option.sub_category_name" :label="option.sub_category_name" v-bind:value="option.sub_category_id">
              </el-option>
            </el-select>
          </base-input>
        </b-col>
      </b-row>
    </div>

    <div class="form-group" v-for="(input,k) in inputs" :key="k">
        <input type="text" class="form-control" v-model="input.name">
        <span>
            <i class="fas fa-minus-circle" @click="remove(k)" v-show="k || ( !k && inputs.length > 1)"></i>
            <i class="fas fa-plus-circle" @click="add(k)" v-show="k == inputs.length-1"></i>
        </span>
    </div>
  </b-card>
</template>

<script>
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Select, Option } from 'element-ui'
  import axios from 'axios'
  import {apiDomain, getHeader} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  import { BasePagination } from '@/components';
  import clientPaginationMixin from '@/assets/js/clientPaginationMixin'
  import debounce from 'lodash.debounce';
  
  export default {
    data() {
      return {
        inputs: [
            {
                name: ''
            }
        ]
      }
    },
    methods: {
        add(index) {
            this.inputs.push({ name: '' });
        },
        remove(index) {
            this.inputs.splice(index, 1);
        }
    }
  }
</script>

