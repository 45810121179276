<template>
  <div>
    <b-row>
      <b-col lg="12">
        <div class="card-wrapper">
          <card>
            <!-- <h3 slot="header" class="mb-0">Edit Template </h3> -->
            <!-- Card header -->
            <div slot="header">
              <b-row >
                <b-col cols="10">
                  <h3 class="mb-0">Edit Template - {{global_template.global_template_name}}</h3>
                </b-col>
                <b-col cols="2" class="text-right">
                  <b-row>
                      <!-- <b-col class="text-right" md="9">
                      </b-col> -->
                      <b-col class="text-right">
                        <el-tooltip content="Click to expand/collapse" placement="top">
                            <base-button type="primary" icon size="sm" v-b-toggle="`collapse-template`">
                              <span class="btn-inner--icon"><i class="fas fa-caret-down"></i></span>
                            </base-button>
                        </el-tooltip>
                      </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <b-collapse id="collapse-template" class="mt-2">
              <form>
                <b-row class="form-group">
                  <b-col md="2" lg="2" >
                    <label for="template-name" class="col-form-label form-control-label">Template Name :</label>
                  </b-col>
                  <b-col md="9">
                    <base-input id="template-name" placeholder="Template Name" :disabled="!isAdmin" v-model="global_template.global_template_name"></base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="2" lg="2" >
                  <label for="template-description" class="col-form-label form-control-label">Template Description :</label>
                  </b-col>
                  <b-col md="9">
                  <base-input>
                    <textarea resize="none" :disabled="!isAdmin" class="form-control" name="" placeholder="Template description" id="template-description" cols="20" rows="3" v-model="global_template.global_template_description"></textarea>
                  </base-input>
                  </b-col>
                </b-row>
                <!-- <b-col> -->
                  <base-button type="primary" class="text-right" v-show="isAdmin" size="md" @click="updateData()">Create / Save</base-button>
                  <base-button type="primary" class="text-right" v-show="isAdmin" size="md" @click="$router.back()">Cancel</base-button>
                <!-- </b-col> -->
              </form>
            </b-collapse>
          </card>
        </div>
      </b-col>
    </b-row>

    <!-- modal to add new text field question -->
    <modal :size="'xl'" :show.sync="modals.addQuestion">
      <h6 slot="header" class="modal-title">{{modals.title}}</h6>
      <form>
          <label for="">Question</label>
          <b-input placeholder="Your Question for Customer" v-model="modals.global_question_title" ></b-input>
          <br>
          <label for="">Translated To (Excel)</label>
          <b-input label="Translated To (Excel)" placeholder="Name View in Excel" v-model="modals.global_question_column"></b-input>
          <br>
          <label for="">Frequency</label>
          <b-form-radio-group v-model="modals.frequency">
            <b-form-radio class="mb-3" v-for="frequency in frequencies" 
              :key="frequency.frequency_id" 
              :value="frequency.frequency_id" 
              :name="frequency.frequency_name">
            {{frequency.frequency_name}}
            </b-form-radio>
          </b-form-radio-group>
      </form>
      
      <template slot="footer">
        <base-button v-if="modals.editButton == false" type="primary" @click.prevent="addQuestion(1)">Save</base-button>
        <base-button v-else type="primary" @click.prevent="saveQuestion(1)">Create / Save</base-button>
        <base-button type="link" class="ml-auto" @click.self="closeModal()">Close</base-button>
      </template>

    </modal>

    <!-- modal to add new yes/no question -->
    <modal :size="'xl'" :show.sync="modals.addYesNoQuestion">
      <h6 slot="header" class="modal-title">{{modals.title}}</h6>
      <form>
        <label for="">Question</label>
        <b-input label="Question" placeholder="Your Question for Customer" v-model="modals.global_question_title" ></b-input>
        <label for="">Translated To (Excel)</label>
        <b-input label="Translated To (Excel)" placeholder="Name View in Excel" v-model="modals.global_question_column"></b-input>
      </form>
      
      <template slot="footer">
        <base-button v-if="modals.editButton == false" type="primary" @click.prevent="addQuestion(2)">Save</base-button>
        <base-button v-else type="primary" @click.prevent="saveQuestion(2)">Create / Save</base-button>
        <base-button type="link" class="ml-auto" @click.self="closeModal()">Close</base-button>
      </template>

    </modal>

    <b-card>
      <h3 slot="header" class="mb-0">Template Question</h3>
    <form>
      <div class="form-group" v-for="(category,index) in template_questions[0].categories" :key="index">
        <!-- <b-col> -->
          <b-row class="form-group" v-show="index == 0 && isAdmin">
            <b-col md="2" lg="2">
              <label for="select-category" class="col-form-label form-control-label">Category : </label>
            </b-col>
            <b-col sm="5" md="5">
              <el-select id="select-category" v-if="selectCategoryOptions.length == 0" v-model="selects.simple" filterable placeholder="Select Category">
                <el-option value="0" disabled>-- No Available Template Category --</el-option>
              </el-select>
              <el-select id="select-category"  v-else v-model="selects.category" filterable placeholder="Select Category" >
                <el-option Selected disabled value="x">-- Please Select Category --</el-option>
                <el-option v-for="option in selectCategoryOptions" :key="option.category_id" :label="option.category_name" :value="option.category_id">
                </el-option>
              </el-select>
            </b-col>
            <b-col class="col-xl-auto col-md-auto col-sm-auto" align-self="center">
              <base-button type="primary" icon size="sm" @click="addCategory()">
                <span class="fa fa-plus"></span>
                <span class="btn-inner--text">Category</span>
              </base-button>
            </b-col>
          </b-row>
        <!-- </b-col> -->
        
        <!-- display category and select sub category -->
        <b-card v-if="template_questions[0].categories[index] != null" v-show="isCategoryEmpty === false">
          <!-- category -->
          <b-row class="form-group">
            <b-col md="6">
              <b-row no-gutters>
                <!-- <b-col md="3" lg="3"> -->
                  <label for="category-name" style="padding:10px;" class="col-form-label form-control-label">Category : 
                  
                  </label>
                  <span id="category-name" style="padding-top:10px;margin-right: 10px;" :value="category.category_name" disabled><b>{{category.category_name}}</b></span>

                <!-- </b-col> -->
                <!-- <b-col md="4" align-self="center"> -->
                <!-- </b-col> -->
                <b-col class="col-md-auto col-sm-auto" v-show="isAdmin" align-self="center">
                  <base-button type="primary" v-show="index != 0" icon size="sm" 
                  @click.prevent="updateTemplateCategorySort(category.global_template_category_id, index - 1, index)">
                    <span class="btn-inner--icon"><i class="ni ni-bold-up"></i></span>
                  </base-button>
                  <base-button type="primary" v-show="index != template_questions[0].categories.length - 1" icon size="sm" 
                  @click.prevent="updateTemplateCategorySort(category.global_template_category_id, index + 1, index)">
                    <span class="btn-inner--icon"><i class="ni ni-bold-down"></i></span>
                  </base-button>
                  <base-button type="primary" icon size="sm" @click.prevent="deleteCategory(category)">
                    <span class="btn-inner--icon"><i class="fas fa-trash"></i></span>
                    <!-- <span class="btn-inner--text">Delete Category</span> -->
                  </base-button>
                </b-col>
              </b-row>
            </b-col>

            <!-- select sub category option -->
            <b-col md="6" v-show="isAdmin">
              <b-row>
                <!-- <b-col md="3" lg="3"> -->
                <label for="select-sub-category" class="col-form-label form-control-label">Sub Category : </label>
                <!-- </b-col> -->
                <b-col md="5">
                  <el-select id="select-sub-category" v-model="sub_category_option[index]" @visible-change="getSubCategoryList(category.category_id)" filterable placeholder="Select Sub Category">
                    <el-option Selected disabled value="x">-- Please Select Sub Category --</el-option>
                    <el-option v-for="(option, key) in selectSubCategoryOptions" :key="key" :label="option.sub_category_name" v-bind:value="option.sub_category_id">
                    </el-option>
                  </el-select>
                </b-col>
                <b-col class="col-md-auto col-sm-auto" align-self="center">
                  <base-button type="primary" icon size="sm" @click.prevent="addSubCategory(sub_category_option[index], category.category_id)">
                    <span class="fa fa-plus"></span>
                    <span class="btn-inner--text">Sub Category</span>
                  </base-button>
                </b-col>
                <b-col class="col-md-auto col-sm-auto text-right" align-self="center">
                  <el-tooltip content="Click to expand/collapse" placement="top">
                    <base-button type="primary" icon size="sm" v-b-toggle="`collapse-category-${category.category_id}`">
                      <span class="btn-inner--icon"><i class="fas fa-caret-down"></i></span>
                    </base-button>
                  </el-tooltip>
                </b-col>
              </b-row>
              
              </b-col>
            <!-- </b-col> -->
          </b-row>
          <b-collapse visible :id="`collapse-category-${category.category_id}`" class="mt-2">
            <!-- dynamic input field for sub category -->
            <div v-for="(sub_category, sub_category_index) in category.sub_categories" :key="sub_category_index">
              <b-row class="form-group">
                <b-col md="8">
                  <label for="sub-category-name" style="padding:10px;" class="col-form-label form-control-label">Sub Category : </label>
                  <span v-if="sub_category != null" style="padding-right:15px; margin-right: 50px;" v-show="isSubCategoryEmpty === false" :value="sub_category.sub_category_name" disabled><b>{{sub_category.sub_category_name}}</b></span>
                  <base-button type="primary" v-show="sub_category_index != 0 && isAdmin" icon size="sm" 
                  @click.prevent="updateTemplateSubCategorySort(sub_category.global_template_category_id, sub_category_index - 1, sub_category_index)">
                    <span class="btn-inner--icon"><i class="ni ni-bold-up"></i></span>
                  </base-button>
                  <base-button type="primary" v-show="sub_category_index != Object.keys(category.sub_categories).length - 1 && isAdmin" icon size="sm" 
                  @click.prevent="updateTemplateSubCategorySort(sub_category.global_template_category_id, sub_category_index + 1, sub_category_index)">
                    <span class="btn-inner--icon"><i class="ni ni-bold-down"></i></span>
                  </base-button>
                  <base-button type="primary" v-show="isAdmin" icon size="sm" @click.prevent="deleteSubCategory(sub_category, category.category_id)">
                    <span class="btn-inner--icon"><i class="fas fa-trash"></i></span>
                    <!-- <span class="btn-inner--text">Delete Sub Category</span> -->
                  </base-button>
                </b-col>
                <b-col md="4" class="text-right" align-self="center">
                  <!-- <b-row>
                    <b-col md="5"> -->
                      <!-- hide question set -->
                      <!-- <el-select id="select-question-type" v-model="question_type_option[sub_category.global_template_category_id]" @visible-change="getQuestionTypeList()" filterable placeholder="Select Question Type">
                        <el-option Selected disabled value="x">-- Please Select Question Type --</el-option>
                        <el-option v-for="(option, key) in selectQuestionTypeOptions" :key="key" :label="option.question_type_name" v-bind:value="option.question_type_id">
                        </el-option>
                      </el-select> -->
                    <!-- </b-col> -->
                    <b-col md="12" align-self="center" v-show="isAdmin">
                      <!-- question_type_option[sub_category.global_template_category_id] -->
                      <base-button type="primary" icon size="sm" @click.prevent="openModal(sub_category.global_template_category_id, 1)">
                        <span class="fa fa-plus"></span>
                        <span class="btn-inner--text">Question</span>
                      </base-button>
                      <el-tooltip content="Click to expand/collapse" placement="top">
                        <base-button type="primary" icon size="sm" v-b-toggle="`collapse-question-${sub_category.global_template_category_id}`" >
                          <span class="btn-inner--icon"><i class="fas fa-caret-down"></i></span>
                        </base-button>
                      </el-tooltip>
                      <!-- <base-button type="primary" icon size="sm" @click.prevent="addQuestionSet(checkboxQuestions[sub_category.global_template_category_id])">
                        <span class="fa fa-plus"></span>
                        <span class="btn-inner--text">Question Set</span>
                      </base-button> -->
                    </b-col>
                  <!-- </b-row> -->
                </b-col>
              </b-row>
              <b-collapse visible :id="`collapse-question-${sub_category.global_template_category_id}`" class="mt-2">
                <!-- question list -->
                <b-card class="bg-default" >
                  <b-form-checkbox-group
                  :id="`checkbox-group-${sub_category.global_template_category_id}`"
                  v-model="checkboxQuestions[sub_category.global_template_category_id]"
                  :name="`checkbox-group-${sub_category.global_template_category_id}`"
                  >
                  <div v-for="(question, question_index) in sub_category.questions" :key="question_index">
                      <b-row class="">
                        <label style="padding-left:10px; color: white;" for="question-title">Question {{question_index+1}} : </label>
                        <b-col sm="7" md="7">
                          <!-- hide question set -->
                          <!-- <b-form-checkbox :value="question.question_id"> -->
                            <template>
                              <div>
                                <p class="text-white">{{question.question_title}} ?</p>
                              </div>
                            </template>
                          <!-- </b-form-checkbox> -->
                        </b-col>
                        <b-col class="text-right" v-show="isAdmin">
                          <base-button type="secondary" v-show="question_index != 0" icon size="sm" @click.prevent="updateQuestionSort(question.question_id, question_index - 1, question_index)">
                            <span class="btn-inner--icon"><i class="ni ni-bold-up"></i></span>
                          </base-button>
                          <base-button type="secondary" v-show="question_index != sub_category.questions.length - 1" icon size="sm" @click.prevent="updateQuestionSort(question.question_id, question_index + 1, question_index)">
                            <span class="btn-inner--icon"><i class="ni ni-bold-down"></i></span>
                          </base-button>
                          <base-button type="secondary" icon size="sm" @click.prevent="editQuestion(question)">
                            <!-- <span class="fas fa-edit"></span> -->
                            <span class="btn-inner--icon"><i class="fas fa-edit"></i></span>
                            <!-- <span class="btn-inner--text">Question</span> -->
                          </base-button>
                          <base-button type="secondary" icon size="sm" @click.prevent="deleteQuestion(question)">
                            <!-- <span class="fas fa-trash"></span> -->
                            <span class="btn-inner--icon"><i class="fas fa-trash"></i></span>
                            <!-- <span class="btn-inner--text">Delete</span> -->
                          </base-button>
                        </b-col>
                      </b-row>
                  </div>
                  </b-form-checkbox-group>
                </b-card>
              </b-collapse>
            </div>
          </b-collapse>
        </b-card>
      </div>
    </form>
    </b-card>
  </div>
</template>

<script>
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Select, Option } from 'element-ui'
  import Modal from "@/components/Modal";
  import axios from 'axios'
  import {apiDomain, getHeader} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  import {getUserInfo} from '@/assets/js/config.js'
  
  export default {
    mixins: [ErrorFunctionMixin],
    components:{
      [Select.name]: Select,
      [Option.name]: Option,
      Modal
    }, 
    data() {
      return {
        isCategoryEmpty: false,
        isSubCategoryEmpty: false,
        addingCategory: false,
        isAdmin: false,
        frequencies: [],
        global_template: {
          global_template_name: null,
          global_template_description: null,
        },
        template_questions: [
          {
            global_template_id: null,
            global_template_name: null,
            categories:[
              {
                category_id: null,
                category_name: null,
                sub_categories: [
                  {
                    sub_category_id: null,
                    sub_category_name: null,
                  }
                ]
              },
            ],
          }
        ],
        selects:{
          category: null,
        },
        sub_category_option:[],
        question_type_option:[],
        selectCategoryOptions:[],
        selectSubCategoryOptions:[],
        selectQuestionTypeOptions:[],
        modals: {
          addQuestion: false,
          addYesNoQuestion: false,
          editButton: false,
          title: null,
          global_question_id: null,
          global_template_category_id: null,
          global_question_column: null,
          global_question_title: null,
          frequency: 1,
        },
        checkboxQuestions: [],
        loginUser : getUserInfo(),
      }
    },
    created() {
      this.loadGlobalTemplateQuestionsDetails();
      this.frequencyList();
      if(this.loginUser.role_id == 1 || this.loginUser.role_id == 2)
      {
        this.isAdmin = true;
      }
    },
    methods: {
      openModal(global_template_category_id, question_type_id) {
        if(question_type_id == 1)
        {
          this.modals.addQuestion = true;
        }
        else if(question_type_id == 2)
        {
          this.modals.addYesNoQuestion = true;
        }
        else
        {
          this.redErrorNotify( '<b>Invalid Add New Question : Please select a question type. </b>');
        }
        
        this.modals.global_template_category_id = global_template_category_id;
        this.modals.title = 'Add New Question';
        this.modals.frequency = 1;
        this.resetModal();
      },
      closeModal() {
        this.modals.addQuestion = false;
        this.modals.addYesNoQuestion = false;
        this.modals.global_template_category_id = null;
        this.resetModal()
      },
      resetModal() {
        this.modals.global_question_column = null;
        this.modals.global_question_title = null;
        this.modals.editButton = false;
      },
      frequencyList() {
        axios.get(apiDomain + 'setup/frequency', { headers: getHeader() })
        .then(response => {
          this.frequencies = response.data.data
          console.log(response.data.data);
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Frequency list error")
        });
      },
      async categoryList() {
        return axios.get(apiDomain + 'setup/category.show_by_global_template/' + this.$route.params.id, { headers: getHeader() })
        .then(response => {
          this.selectCategoryOptions = response.data.data
          this.selects.category = null;
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Add Template Category")
        });
      },
      addCategory() {
        if(!this.selects.category)
        {
          this.redErrorNotify( '<b>Invalid Input : Please select a category. </b>');
        }
        else
        {
          const postData = {
            global_template_id : this.$route.params.id,
            category_id : this.selects.category,
          }
          const str = JSON.stringify(postData);

          axios.post(apiDomain + 'templates/global_template_category', postData ,{ headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              var data = response.data.data[0];
      
              this.$notify({
                message:
                  '<b>Template Category : ' + response.data.data[0].category.category_name + '</b> - Successfully Added.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });

              this.showSubCategoryList(data);

            }
          })
          .catch ( error => {
              console.log(error.response.status)
              this.errorFunction(error, "Add Template Category")
          })
          .finally(() => {
              this.loadGlobalTemplateQuestionsDetails();
          });
        }
      },
      deleteCategory(data) {
        swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          // showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          // denyButtonText: `Don't save`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            axios.delete(apiDomain + 'templates/global_template_category.delete_by_category/' + this.$route.params.id + '/' + data.category_id,{ headers: getHeader() })
            .then ( response => {
              if (response.status === 200)
              {
                this.$notify({
                  message:
                    '<b>Template Category : ' + data.category_name + '</b> - Successfully Deleted.',
                  timeout: 10000,
                  icon: 'ni ni-bell-55',
                  type: 'default',
                });
              }
            })
            .catch ( error => {
                console.log(error.response.status)
                this.errorFunction(error, "Delete Template Category")
                
            })
            .finally(() => {
                this.loadGlobalTemplateQuestionsDetails();
            });
          } 
        })
      },
      showSubCategoryList(data){
        axios.get(apiDomain + 'setup/sub_category.show_by_category/' + data.category.category_id, { headers: getHeader() })
        .then(response => {
          this.selectSubCategoryOptions.push(response.data.data)
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Add Template Sub Category")
        });
      },
      getSubCategoryList(category_id) {
        this.selectSubCategoryOptions = [];

        axios.get(apiDomain + 'setup/sub_category.show_by_global_template/' + this.template_questions[0].global_template_id + '/' + category_id, { headers: getHeader() })
        .then(response => {
          // console.log(response.data.data);
          this.selectSubCategoryOptions = response.data.data;
          // this.sub_category_option = [];
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Add Template Sub Category")
        });
        
        // console.log(this.selectSubCategoryOptions);
      },
      addSubCategory(sub_category_id, category_id) {

        if(!sub_category_id)
        {
          this.redErrorNotify( '<b>Invalid Input : Please select a sub category. </b>');
        }
        else
        {
          const postData = {
            global_template_id : this.$route.params.id,
            category_id : category_id,
            sub_category_id : sub_category_id,
          }
          const str = JSON.stringify(postData);

          axios.post(apiDomain + 'templates/global_template_category', postData ,{ headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              // this.loadGlobalTemplateQuestionsDetails();

              this.$notify({
                message:
                  '<b>Template Sub Category : ' + response.data.data[0].sub_category.sub_category_name + '</b> - Successfully Added.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });

            }
          })
          .catch ( error => {
              console.log(error.response.status)
              this.errorFunction(error, "Add Template Category")
              
          })
          .finally(() => {
              this.loadGlobalTemplateQuestionsDetails();
          });
        }
      },
      deleteSubCategory(data, category_id) {
        swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          // showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          // denyButtonText: `Don't save`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {

            const postData = {
              global_template_category_id: data.global_template_category_id,
              global_template_id : this.$route.params.id,
              category_id : category_id,
              sub_category_id : data.sub_category_id,
            }

            axios.post(apiDomain + 'templates/global_template_category.delete_sub_category', postData,{ headers: getHeader() })
            .then ( response => {
              if (response.status === 200)
              {
                this.$notify({
                  message:
                    '<b>Template Category : ' + data.sub_category_name + '</b> - Successfully Deleted.',
                  timeout: 10000,
                  icon: 'ni ni-bell-55',
                  type: 'default',
                });
                // this.loadGlobalTemplateQuestionsDetails();
              }
            })
            .catch ( error => {
                console.log(error.response.status)
                this.errorFunction(error, "Delete Template Sub Category")
                
            })
            .finally(() => {
              this.loadGlobalTemplateQuestionsDetails();
            });
          } 
        })
      },
      addQuestion(question_type_id) {

        let frequency_id;

        if(question_type_id == 1)
        {
          frequency_id = this.modals.frequency;
        }
        else
        {
          frequency_id = null;
        }

        const postData = {
          global_template_id : this.$route.params.id,
          global_template_category_id : this.modals.global_template_category_id,
          question_type_id : question_type_id,
          frequency_id : frequency_id,
          global_question_title: this.modals.global_question_title,
          global_question_column: this.modals.global_question_column,
        }
        console.log(postData);
        // const str = JSON.stringify(postData);

        axios.post(apiDomain + 'templates/global_question', postData ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.closeModal();
            // this.loadGlobalTemplateQuestionsDetails();

            this.$notify({
              message:
                '<b>Global Question : ' + response.data.data[0].global_question_title + '</b> - Successfully Added.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });

          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Add Global Question")
            
        })
        .finally(() => {
            this.loadGlobalTemplateQuestionsDetails();
        });
      },
      editQuestion(question) {
        this.resetModal();
        if(question.question_type.question_type_id == 1)
        {
          this.modals.addQuestion = true;
        }
        else
        {
          this.modals.addYesNoQuestion = true;
        }
        this.modals.editButton = true;
        this.modals.title = 'Edit Question';

        axios.get(apiDomain + 'templates/global_question/' + question.question_id, { headers: getHeader() })
        .then(response => {
          if(response.status === 200)
          {
            var question = response.data.data[0];
            this.modals.global_question_id = question.global_question_id;
            this.modals.global_question_title = question.global_question_title;
            this.modals.global_question_column = question.global_question_column;
            this.modals.frequency = question.frequency.frequency_id;
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Show Global Question")
        });
      },
      saveQuestion(question_type_id)
      {
        let frequency_id;

        if(question_type_id == 1)
        {
          frequency_id = this.modals.frequency;
        }
        else
        {
          frequency_id = null;
        }

        const postData = {
          global_template_id: this.$route.params.id,
          question_type_id : question_type_id,
          frequency_id : frequency_id,
          global_question_title: this.modals.global_question_title,
          global_question_column: this.modals.global_question_column,
        }
        const str = JSON.stringify(postData);
        // console.log(str);
        // console.table(postData);

        axios.put(apiDomain + 'templates/global_question/' + this.modals.global_question_id, postData  ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.closeModal();
            // this.loadGlobalTemplateQuestionsDetails();

            this.$notify({
              message:
                '<b>Global Question : ' + response.data.data[0].global_question_title + '</b> - Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });

          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Update Global Question")
            
        })
        .finally(() => {
            this.loadGlobalTemplateQuestionsDetails();
        });
      },
      deleteQuestion(question) {
        swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          // showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          // denyButtonText: `Don't save`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            axios.delete(apiDomain + 'templates/global_question/' + question.question_id, { headers: getHeader() })
            .then ( response => {
              if (response.status === 200)
              {
                this.$notify({
                  message:
                    '<b>Global Question : ' + question.question_title + '</b> - Successfully Deleted.',
                  timeout: 10000,
                  icon: 'ni ni-bell-55',
                  type: 'default',
                });
                // this.loadGlobalTemplateQuestionsDetails();
              }
            })
            .catch ( error => {
                console.log(error.response.status)
                this.errorFunction(error, "Delete Global Question")
                
            })
            .finally(() => {
              this.loadGlobalTemplateQuestionsDetails();
            });
          } 
        })
      },
      async getGlobalTemplateQuestionList() {
        return axios.get(apiDomain + 'templates/global_template.show_all_questions/' + this.$route.params.id, { headers: getHeader() })
        .then(response => {
          // console.log(Object.keys(response.data.categories).length);
          console.log(response.data);
          if(Object.keys(response.data.categories).length > 0)
          {
            this.template_questions = [];
            this.template_questions[0] = response.data
            this.isCategoryEmpty = false;
          }
          else
          {
            this.isCategoryEmpty = true;
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Show Global Template Category")
        });
      },
      async getGlobalTemplate() {
        return axios.get(apiDomain + 'templates/global_template/' + this.$route.params.id ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.global_template.global_template_name = response.data.data[0].global_template_name
            this.global_template.global_template_description  = response.data.data[0].global_template_description
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Show Template")
        });
      },
      updateData(){
        const postData = {
          global_template_name : this.global_template.global_template_name,
          global_template_description : this.global_template.global_template_description,
        }
        
        const str = JSON.stringify(postData);
        console.log(str);
        console.table(postData);

        axios.put(apiDomain + 'templates/global_template/' + this.$route.params.id , postData ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.$notify({
              message:
                '<b>Edit Template : ' + this.global_template.global_template_name + '</b> - Successfully Saved.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Edit Template")
            
        });
      },
      async loadGlobalTemplateQuestionsDetails() {
        await this.getGlobalTemplate();
        await this.getGlobalTemplateQuestionList();
        await this.categoryList();
      },
      updateTemplateCategorySort(global_template_category_id, new_sort_value, current_sort_value)
      {
        const postData = {
          global_template_category_id : global_template_category_id,
          new_sort_value : new_sort_value,
          current_sort_value : current_sort_value,
        }

        axios.put(apiDomain + 'templates/global_template_category.update_category_sort', postData ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            return this.getGlobalTemplateQuestionList();
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Update Global Template Category Sort")
            
        });
      },
      updateTemplateSubCategorySort(global_template_category_id, new_sort_value, current_sort_value)
      {
        const postData = {
          global_template_category_id : global_template_category_id,
          new_sort_value : new_sort_value,
          current_sort_value : current_sort_value,
        }

        axios.put(apiDomain + 'templates/global_template_category.update_sub_category_sort', postData ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            return this.getGlobalTemplateQuestionList();
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Update Global Template Sub Category Sort")
            
        });
      },
      updateQuestionSort(global_question_id, new_sort_value, current_sort_value)
      {
        const postData = {
          global_question_id : global_question_id,
          new_sort_value : new_sort_value,
          current_sort_value : current_sort_value,
        }

        axios.put(apiDomain + 'templates/global_question.update_question_sort', postData ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            return this.getGlobalTemplateQuestionList();
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Update Global Question Sort")
            
        });
      },
      getQuestionTypeList() {
        axios.get(apiDomain + 'setup/question_type', { headers: getHeader() })
        .then(response => {
          this.selectQuestionTypeOptions = response.data.data
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Add Question Type")
        });
      },
      addQuestionSet(checkboxQuestions) {
        const postData = {
          global_questions: checkboxQuestions
        }

        // console.log(postData);

        axios.put(apiDomain + 'templates/global_question.add_question_set', postData, { headers: getHeader() })
        .then(response => {
          this.$notify({
            message:
              '<b>Question Set : Successfully Added.',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });

          this.checkboxQuestions = [];
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Error Adding Question Set")
        });
      }
    }
  }
</script>


