<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Template Questions</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="12" cols="5" class="text-right">
          <base-button size="sm" type="primary" @click="close()">Back / Close</base-button>
          <!-- <base-button type="primary" icon size="sm" @click.prevent="close()">Close</base-button> -->
        </b-col>
        <b-col class="text-center">
        </b-col>
        <!-- <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="primary" @click="$router.back()">Back</base-button> -->
          <!-- <base-button size="sm" type="neutral">Filters</base-button> -->
        <!-- </b-col> -->
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <global-template-questions-details :global-template-name="global_template_name"></global-template-questions-details>
      <!-- <global-template-questions-details-v2 :global-template-name="global_template_name"></global-template-questions-details-v2> -->
    </b-container>

  </div>
</template>

<script>
  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
  import GlobalTemplateQuestionsDetails from "@/views/GlobalTemplate/GlobalTemplateQuestionsDetails";
  import GlobalTemplateQuestionsDetailsV2 from "@/views/GlobalTemplate/GlobalTemplateQuestionsDetailsV2";
  import axios from 'axios'
  import {apiDomain, getHeader} from '@/assets/js/config.js'

  export default {
    components: {
      GlobalTemplateQuestionsDetails,
      GlobalTemplateQuestionsDetailsV2,
      RouteBreadCrumb,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    data() {
      return {
        global_template_name: null,
        prevRoute: null,
      };
    },
    created(){
      axios.get(apiDomain + 'templates/global_template/' + this.$route.params.id, { headers: getHeader() })
      .then(response => {
        this.global_template_name = response.data.data[0].global_template_name
      })
      .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Global Template Questions")
      });
    },
    methods: {
      close() {
        if(this.$route.params.flag == 1)
        {
          window.close();
        }
        else
        {
          this.$router.back();
        }
      }, 
    }
  };
</script>
