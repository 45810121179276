<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">KYC</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </b-col>
        <!--
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
        -->
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Edit Customer</h3>
              <h5 class="text-danger">(*) indicates input must be filled or a value from the list must be selected</h5>
              <!-- Card body -->
              <form>
                <!-- Input groups with icon -->
                <b-row>
                  <b-col md="3">
                    <label class="form-control-label">Customer Name <span class="text-danger">*</span></label>
                    <base-input placeholder="Customer name" v-model="customer.customer_name"></base-input>
                  </b-col>
                  <b-col md="3">
                    <label class="form-control-label">Customer IC <span class="text-danger">*</span></label>
                    <base-input placeholder="Customer ic" v-model="customer.customer_ic"></base-input>
                  </b-col>
                  <b-col md="3">
                    <label class="form-control-label">Customer Email <span class="text-danger">*</span></label>
                    <base-input placeholder="Customer email" v-model="customer.customer_email"></base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="3">
                    <label class="form-control-label">Customer Contact <span class="text-danger">*</span></label>
                    <base-input placeholder="Customer contact" v-model="customer.customer_contact"></base-input>
                  </b-col>
                  <b-col md="3">
                    <label class="form-control-label">Customer From <span class="text-danger">*</span></label>
                    <base-input>
                      <el-select v-model="selects.simple" filterable
                                  placeholder="Select Customer From">
                        <el-option v-for="option in selectOptions"
                                    :key="option.external_application_id"
                                    :label="option.external_application_name"
                                    :value="option.external_application_id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                  <b-col md="3">
                    <label class="form-control-label">Customer External Id <span class="text-danger">*</span></label>
                    <base-input placeholder="Customer external id" v-model="customer.external_customer_id"></base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <label class="form-control-label">Select KYC Template <span class="text-danger">*</span></label>
                    <base-input>
                      <el-select v-model="selects.template" filterable
                                  placeholder="Select KYC Template" @change="selectTemplate()">
                        <el-option Selected disabled value="x">-- Please Select KYC Template --</el-option>
                        <el-option v-for="option in selectTemplateOptions"
                                    :key="option.global_template_id"
                                    :label="option.global_template_name"
                                    :value="option.global_template_id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                  <b-col md="3" class="col-xl-auto" style="padding-top:20px" align-self="center">
                    <base-button type="primary" icon @click.prevent="goToTemplate()">
                      <!-- <span class="btn-inner--icon"><i class="fas fa-trash"></i></span> -->
                      <span class="btn-inner--text">Go to Template</span>
                    </base-button>
                  </b-col>
                </b-row>

                <base-button type="primary" @click="updateData()">Create / Save</base-button>
                <base-button type="primary" @click="createData()">Send KYC Template to Customer</base-button>
                <base-button type="primary" @click="$router.back()">Cancel</base-button>
              </form>
              <br><br>
              <div v-if="kyc_form_link != null">
                <label for="" class="text-danger">KYC Form Link : </label>
                  <a :href="`${kyc_form_link}`" target="_blank" rel="noopener noreferrer" style="font-size:x-large;" class="table-action" data-toggle="tooltip" data-original-title="Group Edit"><b>{{kyc_form_link}}</b></a>
                  <base-button type="primary" size="sm" @click="copyLink(kyc_form_link)">Copy Link</base-button>
                <div>
                  <label for="" class="text-danger">OTP : </label><span> {{otp}}</span> <base-button type="primary" size="sm" @click="copyOTP(otp)">Copy OTP</base-button>
                </div>              
              </div>
            </card>

          </div>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
  import { Select , Option } from 'element-ui'
  import axios from 'axios'
  import {apiDomain, homeLink,getHeader} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components:{
      [Select.name]: Select,
      [Option.name]: Option,
    },   
    data() {
      return {
        customer: {
          customer_name: null,
          customer_ic: null,
          customer_email: null,
          customer_contact: null,
          external_customer_id: null,
        },
        selects : {
          simple: null,
          template: null,
        },
        selectOptions: [],
        selectTemplateOptions: [],
        template_name: null,
        kyc_customer: null,
        customer_form_id: null,
        kyc_form_link: null,
        otp: null,

      }
    },
    created(){
        this.externalApplicationList(),
        this.templateList(),
        axios.get(apiDomain + 'customers/customer/' + this.$route.params.id ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.customer = response.data.data[0]
            this.selects.simple = response.data.data[0].external_application.external_application_id
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Show Customer")
            
        });
          const str = JSON.stringify(this.selects.simple);
          console.log(str);
          console.table(this.selects.simple);
    },
    methods: {
      copyLink(link) {
        if(navigator.clipboard && window.isSecureContext)
        {
          navigator.clipboard.writeText(link);
        }
        else
        {
          this.unsecureCopyToClipboard(link);
        }
        // navigator.clipboard.writeText(link);
        this.$notify({
          message:
            '<b>KYC Link copied to clipboard',
          timeout: 1000,
          icon: 'ni ni-bell-55',
          type: 'default',
        });
      },
      copyOTP(otp) {
        if(navigator.clipboard && window.isSecureContext)
        {
          navigator.clipboard.writeText(otp);
        }
        else
        {
          this.unsecureCopyToClipboard(otp);
        }
        // navigator.clipboard.writeText(otp);
        this.$notify({
          message:
            '<b>OTP copied to clipboard.',
          timeout: 1000,
          icon: 'ni ni-bell-55',
          type: 'default',
        });
      },
      unsecureCopyToClipboard(link)
      {
        let dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = link;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
      },
      goToTemplate() {
        if(!this.selects.template)
        {
            this.redErrorNotify( '<b>Invalid Input : Please select a template to send to customer. </b>');
        }
        else
        {
          let route = this.$router.resolve({path: '/templates/globalTemplateQuestions/' + this.selects.template + '/1'})
          window.open(route.href, '_blank');
          // this.$router.push({path: '/templates/globalTemplateQuestions/' + this.selects.template });
        }
      },
      createData(){
        if(!this.selects.template) 
        {
            this.redErrorNotify( '<b>Invalid Input : Please select a template to send to customer. </b>');
        } else {
          const customerData = {
            customer_id: null,
            customer_form_id: null,
          }

          const postData = {
            global_template_id: this.selects.template,
            customer_id: this.$route.params.id,
            customer_form_name: this.template_name,
            customer_form_description: this.template_description,
          }

          axios.post(apiDomain + 'customers/customer_form', postData, { headers: getHeader() })
          .then(response => {
            if(response.status === 200)
            {
              this.customer_form_id = response.data.data[0].customer_form_id,
              
              this.$notify({
                message:
                  '<b>Customer Form : ' + response.data.data[0].customer_form_name + '</b> - Successfully Generated.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });

              customerData.customer_id = this.$route.params.id,
              customerData.customer_form_id = this.customer_form_id
              window.localStorage.setItem('customerData', JSON.stringify(customerData))

              this.kyc_form_link = homeLink + '/kyc_forms/' + this.$route.params.id + '/' + this.customer_form_id + '/' + this.customer.link_customer_name
              this.otp = response.data.data[0].otp;
              // this.kyc_form_link = 'https://google.com'
            }
          })
          .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Generate Customer Form Error")
          })
          .finally (() => {
            this.sendEmail();
          });

        }
      },
      sendEmail(){
        const emailData = {
          customer_name: this.customer.customer_name,
          customer_email: this.customer.customer_email,
          customer_form_id: this.customer_form_id,
          kyc_form_link: this.kyc_form_link,
        }

        axios.post(apiDomain + 'customers/customer_form.send_email', emailData, { headers: getHeader() })
        .then(response => {
          if(response.status === 200)
          {                  
            this.$notify({
              message:
                '<b>Customer Form To ' + this.customer.customer_email + '</b> - Successfully Sent.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Customer Form Error Sending Email")
        });
      },
      updateData(){
          const postData = {
            customer_name : this.customer.customer_name,
            customer_ic : this.customer.customer_ic,
            customer_email : this.customer.customer_email,
            customer_contact : this.customer.customer_contact,
            external_application_id  : this.selects.simple,
            external_customer_id : this.customer.external_customer_id,
          }
          
          const str = JSON.stringify(postData);
          console.log(str);
          console.table(postData);

          axios.put(apiDomain + 'customers/customer/' + this.$route.params.id , postData ,{ headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              this.$notify({
                message:
                  '<b>Edit Customer : ' + this.customer.customer_name + '</b> - Successfully Saved.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });
              this.$router.back()
            }
          })
          .catch ( error => {
              console.log(error.response.status)
              this.errorFunction(error, "Edit Customer")
              
          });

      },
      templateList(){
        axios.get(apiDomain + 'templates/global_template', { headers: getHeader() })
        .then(response => {
          this.selectTemplateOptions = response.data.data
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Get Template Error")
        });
      },
      externalApplicationList(){
        axios.get(apiDomain + 'setup/external_application', { headers: getHeader() })
        .then(response => {
            this.selectOptions = response.data.data
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Edit Customer")
        });
      },
      selectTemplate(){
        axios.get(apiDomain + 'templates/global_template/' + this.selects.template, { headers: getHeader() })
        .then(response => {
          this.template_name = response.data.data[0].global_template_name,
          this.template_description = response.data.data[0].global_template_description
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Get Global Template Name Error")
        });
      }
    }
  }
</script>
