<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">New KYC</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </b-col>
        <!--
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
        -->
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Customer Details</h3>
              <h5 class="text-danger">(*) indicates input must be filled or a value from the list must be selected</h5>
              <!-- Card body -->
              <form>
                <!-- Input groups with icon -->
                <b-row>
                  <b-col md="3">
                    <base-input label="Customer Name" disabled placeholder="Customer name" v-model="customer.customer_name"></base-input>
                  </b-col>
                  <b-col md="3">
                    <base-input label="Customer IC" disabled placeholder="Customer ic" v-model="customer.customer_ic"></base-input>
                  </b-col>
                  <b-col md="3">
                    <base-input  label="Customer Email" disabled placeholder="Customer email" v-model="customer.customer_email"></base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- <b-col md="3">
                    <base-input  label="Customer External Id" disabled placeholder="Customer external id" v-model="customer.external_customer_id"></base-input>
                  </b-col> -->
                  <b-col md="3">
                    <base-input label="Customer Contact" disabled placeholder="Customer contact" v-model="customer.customer_contact"></base-input>
                  </b-col>
                  <b-col md="3">
                    <base-input  label="Customer From" disabled placeholder="Customer From" v-model="customer.external_application_name"></base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <label class="form-control-label">Select KYC Template <span class="text-danger">*</span></label>
                    <base-input>
                      <el-select v-model="selects.template" filterable
                                  placeholder="Select KYC Template" @change="selectTemplate()">
                        <el-option Selected disabled value="x">-- Please Select KYC Template --</el-option>
                        <el-option v-for="option in selectTemplateOptions"
                                    :key="option.global_template_id"
                                    :label="option.global_template_name"
                                    :value="option.global_template_id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                  <b-col md="3" class="col-xl-auto" style="padding-top:20px" align-self="center">
                    <base-button type="primary" icon size="md" @click.prevent="goToTemplate()">
                      <!-- <span class="btn-inner--icon"><i class="fas fa-trash"></i></span> -->
                      <span class="btn-inner--text">Go to Template</span>
                    </base-button>
                  </b-col>
                </b-row>
                
                <base-button type="primary" @click="createData()">Send KYC Template to Customer</base-button>
                <base-button type="primary" @click="back()">Cancel / Close</base-button>
              </form>
              <br><br>
              <div v-if="kyc_form_link != null">
                <label for="" class="text-danger">KYC Form Link : </label>
                  <a :href="`${kyc_form_link}`" target="_blank" rel="noopener noreferrer" style="font-size:x-large;" class="table-action" data-toggle="tooltip" data-original-title="Group Edit"><b>{{kyc_form_link}}</b></a>
                  <base-button type="primary" size="sm" @click="copyLink(kyc_form_link)">Copy Link</base-button>
                <div>
                  <label for="" class="text-danger">OTP : </label><span> {{otp}}</span> <base-button type="primary" size="sm" @click="copyOTP(otp)">Copy OTP</base-button>
                </div>  
              </div>
            </card>

          </div>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
  import { Select , Option } from 'element-ui'
  import axios from 'axios'
  import {apiDomain, getHeader, homeLink} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components:{
      [Select.name]: Select,
      [Option.name]: Option,
    },   
    props: ['external_customer_id', 'external_application_name'],
    data() {
      return {
        customer: {
          customer_name: null,
          customer_ic: null,
          customer_email: null,
          customer_contact: null,
          external_application_name: null,
          external_customer_id: null,
        },
        selects:{
          simple:null,
          template:null,
        },
        selectOptions:[],
        selectTemplateOptions: [],
        template_name: null,
        template_description: null,
        kyc_customer: null,
        customer_form_id: null,
        kyc_form_link: null,
        otp: null,
        prevRoute: null,
      }
    },
    created() {
      this.externalApplicationList();
      this.templateList();
      if(this.external_application_name == 'IM2U')
      {
        this.im2uCustomerList();
      }
      else if(this.external_application_name == 'IM2U V2')
      {
        this.im2uV2CustomerList();
      }
      else
      {
        this.dsrCustomerList()
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.prevRoute = from
      })
    },
    methods: {
      copyLink(link) {
        if(navigator.clipboard && window.isSecureContext)
        {
          navigator.clipboard.writeText(link);
        }
        else
        {
          this.unsecureCopyToClipboard(link);
        }
        // navigator.clipboard.writeText(link);
        this.$notify({
          message:
            '<b>KYC Link copied to clipboard',
          timeout: 1000,
          icon: 'ni ni-bell-55',
          type: 'default',
        });
      },
      copyOTP(otp) {
        if(navigator.clipboard && window.isSecureContext)
        {
          navigator.clipboard.writeText(otp);
        }
        else
        {
          this.unsecureCopyToClipboard(otp);
        }
        this.$notify({
          message:
            '<b>OTP copied to clipboard.',
          timeout: 1000,
          icon: 'ni ni-bell-55',
          type: 'default',
        });
      },
      unsecureCopyToClipboard(link)
      {
        let dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = link;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
      },
      goToTemplate() {
        // console.log(document.referrer);
        if(!this.selects.template)
        {
            this.redErrorNotify( '<b>Invalid Input : Please select a template to send to customer. </b>');
        }
        else
        {
          let route = this.$router.resolve({path: '/templates/globalTemplateQuestions/' + this.selects.template +'/1'})
          window.open(route.href, '_blank');
          // this.$router.push({path: '/templates/globalTemplateQuestions/' + this.selects.template });
        }
      },
      createData(){
        if(!this.selects.template) 
        {
            this.redErrorNotify( '<b>Invalid Input : Please select a template to send to customer. </b>');
        } else {
          const postData = {
            customer_name : this.customer.customer_name,
            customer_ic : this.customer.customer_ic,
            customer_email : this.customer.customer_email,
            customer_contact : this.customer.customer_contact,
            external_application_name  : this.customer.external_application_name,
            external_customer_id : this.customer.external_customer_id,
          }

          const customerData = {
            customer_id: null,
            customer_form_id: null,
          }

          const str = JSON.stringify(postData);
          // console.log(str);
          // console.table(postData);

          axios.post(apiDomain + 'customers/customer', postData ,{ headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              this.kyc_customer = response.data.data[0];
              this.$notify({
                message:
                  '<b>Customer Data : ' + response.data.data[0].customer_name + '</b> - Successfully Saved.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });
              // this.$router.back()

              const postData = {
                global_template_id: this.selects.template,
                customer_id: this.kyc_customer.customer_id,
                customer_form_name: this.template_name,
                customer_form_description: this.template_description,
              }

              axios.post(apiDomain + 'customers/customer_form', postData, { headers: getHeader() })
              .then(response => {
                if(response.status === 200)
                {
                  this.customer_form_id = response.data.data[0].customer_form_id,
                  
                  this.$notify({
                    message:
                      '<b>Customer Form : ' + response.data.data[0].customer_form_name + '</b> - Successfully Generated.',
                    timeout: 10000,
                    icon: 'ni ni-bell-55',
                    type: 'default',
                  });

                  customerData.customer_id = this.kyc_customer.customer_id,
                  customerData.customer_form_id = this.customer_form_id
                  window.localStorage.setItem('customerData', JSON.stringify(customerData))

                  this.kyc_form_link = homeLink + '/kyc_forms/' + this.kyc_customer.customer_id + '/' + this.customer_form_id + '/' + this.kyc_customer.link_customer_name
                  this.otp = response.data.data[0].otp;
                }
              })
              .catch ( error => {
                console.log(error.response.status)
                this.errorFunction(error, "Generate Customer Form Error")
              })
              .finally (() => {
                this.sendEmail();
              });

            }
          })
          .catch ( error => {
              console.log(error.response.status)
              this.errorFunction(error, "Add Customer")
              
          });
        }
      },
      sendEmail(){
        const emailData = {
          customer_name: this.customer.customer_name,
          customer_email: this.customer.customer_email,
          customer_form_id: this.customer_form_id,
          kyc_form_link: this.kyc_form_link,
        }

        axios.post(apiDomain + 'customers/customer_form.send_email', emailData, { headers: getHeader() })
        .then(response => {
          if(response.status === 200)
          {                  
            this.$notify({
              message:
                '<b>Customer Form To ' + this.customer.customer_email + '</b> - Successfully Sent.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Customer Form Error Sending Email")
        });
      },
      selectTemplate(){
        axios.get(apiDomain + 'templates/global_template/' + this.selects.template, { headers: getHeader() })
        .then(response => {
          this.template_name = response.data.data[0].global_template_name,
          this.template_description = response.data.data[0].global_template_description
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Get Global Template Name Error")
        });
      },  
      dsrCustomerList(){
        axios.get(apiDomain + 'customers/customer.get_dsr_customer_list/' + this.external_customer_id, { headers: getHeader() })
        .then(response => {
          var data = response.data[0];
          this.customer.customer_name = data.Cust_Name;
          this.customer.customer_ic = data.Cust_NRIC;
          this.customer.customer_email = data.Cust_Email;
          this.customer.customer_contact = data.Cust_Contact;
          this.customer.external_application_name = this.external_application_name;
          this.customer.external_customer_id = this.external_customer_id;
          // console.log(this.customer);
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Delete Customer") 
        });
        const str = JSON.stringify(this.tableData, null, 2);
        console.log(str);
      },
      im2uCustomerList(){
        axios.get(apiDomain + 'customers/customer.get_im2u_customer_list/' + this.external_customer_id, { headers: getHeader() })
        .then(response => {
          var data = response.data[0];
          this.customer.customer_name = data.customer_name;
          this.customer.customer_ic = data.nric;
          this.customer.customer_email = data.email_address;
          this.customer.customer_contact = data.contact_number;
          this.customer.external_application_name = this.external_application_name;
          this.customer.external_customer_id = this.external_customer_id;
          // console.log(this.customer);
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Delete Customer") 
        });
        const str = JSON.stringify(this.tableData, null, 2);
        console.log(str);
      },
      im2uV2CustomerList(){
        axios.get(apiDomain + 'customers/customer.get_im2uv2_customer_list/' + this.external_customer_id, { headers: getHeader() })
        .then(response => {
          var data = response.data[0];
          this.customer.customer_name = data.customer_name;
          this.customer.customer_ic = data.nric;
          this.customer.customer_email = data.email_address;
          this.customer.customer_contact = data.contact_number;
          this.customer.external_application_name = this.external_application_name;
          this.customer.external_customer_id = this.external_customer_id;
          // console.log(this.customer);
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Delete Customer") 
        });
        const str = JSON.stringify(this.tableData, null, 2);
        console.log(str);
      },
      templateList(){
        axios.get(apiDomain + 'templates/global_template', { headers: getHeader() })
        .then(response => {
          this.selectTemplateOptions = response.data.data
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Get Template Error")
        });
      },
      externalApplicationList(){
        axios.get(apiDomain + 'setup/external_application', { headers: getHeader() })
        .then(response => {
          this.selectOptions = response.data.data
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Get External Application Error")
        });
        // console.log(this.info)   
      },
      back() {
        if(this.$route.params.token)
        {
          // window.location.href = "https://sas.imortgage2u.com/portal/initialSettlement/proposal/"+this.$route.params.proposal_id;
          window.history.back();
          window.close();
        }
        else
        {
          this.$router.back();
        }
      },
      // toKycForm(){
      //   this.$router.push({path: '/kyc_forms/' + this.kyc_customer.customer_id + '/'+ this.customer_form_id})
      // }
    }
  }
</script>
