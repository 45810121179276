<template>
  <b-card no-body>
    <b-card-header class="border-0">
      <b-row >
        <b-col cols="6">
          <h3 class="mb-0">KYC Templates</h3>
        </b-col>
        <!-- <b-col cols="6" class="text-right">
          <el-tooltip content="Add a new Template" placement="top">
            <base-button type="primary" icon size="sm" @click="addGlobalTemplate()">
              <span class="btn-inner--icon"><i class="fas fa-edit"></i></span>
              <span class="btn-inner--text">Add</span>
            </base-button>

          </el-tooltip>
        </b-col> -->
      </b-row>
    </b-card-header>
    <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between">
      <el-select
        class="select-primary pagination-select p-2"
        v-model="pagination.perPage"
        placeholder="Per page"
      >
        <el-option
          class="select-primary"
          v-for="item in pagination.perPageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <div class="p-2">
        <base-input v-model="searchText"
        v-on:input="debouncedHandler"
                    prepend-icon="fas fa-search"
                    placeholder="Search...(case-sensitive)">
        </base-input>
      </div>
    </b-col>
    <el-table class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="queriedData"
              @sort-change="sortChange"
              @selection-change="selectionChange"
              row-key="global_template_id">
      <el-table-column label="id"
                       min-width="60px"
                       prop="global_template_id"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.global_template_id}}</b>
        </template>
      </el-table-column>

      <el-table-column label="Name"
                       prop="global_template_name"
                       min-width="150px"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.global_template_name}}</b>
        </template>
      </el-table-column>

      <el-table-column id="description" label="Description"
                       prop="global_template_description"
                       min-width="250px"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.global_template_description}}</b>
          <div>
            <li>Total Categories: {{row.global_template_category.category_count}}</li>
            <li>Total Sub Categories: {{row.global_template_category.sub_category_count}}</li>
            <li>Total Questions: {{row.global_template_question.question_count}}</li>
          </div>         
        </template>
      </el-table-column>

      <el-table-column label="Created By"
                       min-width="150px"
                       prop="created_by"
                       sortable>
        <template v-slot="{row}">
          <b>{{row.created_by.name}}</b>
        </template>
      </el-table-column>

      <el-table-column min-width="100px" align="center">
        <div slot-scope="{row}" class="table-actions btn-group-vertical">
          <!-- <el-tooltip content="Edit" placement="top">
            <a href="#!" @click.prevent="onEdit(row)" class="table-action" data-toggle="tooltip" data-original-title="Global Template Edit">
              <i class="fas fa-edit"></i>
            </a> -->
            <!-- <base-button type="primary" icon size="sm" @click.prevent="onEdit(row)">
              <span class="btn-inner--icon"><i class="fas fa-edit"></i></span>
              <span class="btn-inner--text">Edit</span>
            </base-button> -->
          <!-- </el-tooltip> -->
          <!-- <el-tooltip content="Questions" placement="top">
            <a href="#!" @click.prevent="onQuestion(row)" class="table-action" data-toggle="tooltip" data-original-title="Global Template Questions">
              <i class="fas fa-file"></i>
            </a> -->
            <base-button type="primary" icon size="sm" @click.prevent="onQuestion(row)">
              <!-- <span class="btn-inner--icon"><i class="fas fa-file"></i></span> -->
              <span class="btn-inner--text">Question</span>
            </base-button>
          <!-- </el-tooltip> -->
          <!-- <el-tooltip content="Delete" placement="top"> -->
            <!-- <a href="#!" @click.prevent="onDelete(row)" class="table-action table-action-delete" data-toggle="tooltip"
               data-original-title="Delete product">
              <i class="fas fa-trash"></i>
            </a> -->
            <template v-if="loginUser.role_id != 3">
              <base-button type="primary" icon size="sm" @click.prevent="onDelete(row)">
                <!-- <span class="btn-inner--icon"><i class="fas fa-trash"></i></span> -->
                <span class="btn-inner--text">Delete</span>
              </base-button>
            </template>
          <!-- </el-tooltip> -->
        </div>
      </el-table-column>
    </el-table>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class="">
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

          <span v-if="selectedRows.length">
            &nbsp; &nbsp; {{selectedRows.length}} rows selected
          </span>
        </p>

      </div>
      <base-pagination
        class="pagination-no-border"
        :current="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
        @change="paginationChanged($event)"
      >
      </base-pagination>
    </div>

  </b-card>

</template>
<script>
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Select, Option } from 'element-ui'
  import axios from 'axios'
  import {apiDomain, getHeader} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  import { BasePagination } from '@/components';
  import clientPaginationMixin from '@/assets/js/clientPaginationMixin'
  import debounce from 'lodash.debounce';
  import {getUserInfo} from '@/assets/js/config.js'

  export default {
    name: 'global-templates-table',
    mixins: [ErrorFunctionMixin,clientPaginationMixin],
    components: {
      BasePagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Tooltip.name]: Tooltip,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        propsToSearch:['global_template_name'],
        tableData: [],
        currentPage: 1,
        mode: 'listing',
        selectedRows: [],
        searchText: '',
        debouncedHandler: '',
        loginUser: getUserInfo(),
        TableColumn:[{
          type: 'selection'
        }]
      };
    },
    mounted(){
      this.globalTemplateList(),
      this.debouncedHandler = debounce(() => {
        this.searchGlobalTemplateList();
      }, 500);
    },
    methods: {
      paginationChanged(page) {
        this.pagination.currentPage = page
      },
      selectionChange(selectedRows) {
      this.selectedRows = selectedRows
      },
      addGlobalTemplate(){
        this.$router.push({path: '/templates/addGlobalTemplates' })
      },
      onEdit(row) {
        this.$router.push({path: '/templates/editGlobalTemplates/' + row.global_template_id })
      },
      // onSetting(row) {
      //   this.$router.push({path: '/group/settingGlobalTemplate/' + row.global_template_id })
      // },
      // onMember(row) {
      //   this.$router.push({path: '/group/memberGlobalTemplate/' + row.global_template_id })
      // },
      onQuestion(row) {
        this.$router.push({path: '/templates/globalTemplateQuestions/' + row.global_template_id })
      },
      onDelete(row) {
        swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          // showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          // denyButtonText: `Don't save`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            axios.delete(apiDomain + 'templates/global_template/' + row.global_template_id,{ headers: getHeader() })
            .then ( response => {
              if (response.status === 200)
              {
                this.$notify({
                  message:
                    '<b>Global Template : ' + row.global_template_name + '</b> - Successfully Deleted.',
                  timeout: 10000,
                  icon: 'ni ni-bell-55',
                  type: 'default',
                });
                this.globalTemplateList()
              }
            })
            .catch ( error => {
                console.log(error.response.status)
                this.errorFunction(error, "Delete Global Template")
                
            });
          } 
        })
      },
      globalTemplateList(){
        axios.get(apiDomain + 'templates/global_template', { headers: getHeader() })
        .then(response => {
          this.tableData = response.data.data
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Delete Global Template")
            
        });
        const str = JSON.stringify(this.tableData, null, 2);
        console.log(str);
      },
      searchGlobalTemplateList()
      {
        const postData = {
          controller_name : "GlobalTemplateController",
          search_text : this.searchText,
          table_column: ["global_template_name"]
        }

        axios.post(apiDomain + 'templates/global_template.data_search', postData  ,{ headers: getHeader() })
        .then ( response => {
          if(response.data.data.length === 0){
            return this.$notify({
              message:
                '<b>Global Template : </b> - No Results.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
          }
          if (response.status === 200)
          {
            this.tableData = response.data.data
            console.log(this.tableData)
            this.$notify({
              message:
                '<b>Global Template : </b> - Search Successful.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });

          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Global Template Listing")
        });
      }

    }
  }
</script>
<style>
.el-table .cell, #description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
  word-break: break-all;
  line-height: 23px;
}
</style>
