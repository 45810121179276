<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">User</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </b-col>
        <!--
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
        -->
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Edit User</h3>
              <!-- Card body -->
              <form>
                <!-- Input groups with icon -->
                <b-row>
                  <b-col md="4">
                    <base-input label="Username" placeholder="Your name" v-model="user.name"></base-input>
                  </b-col>
                  <b-col md="4">
                    <base-input label="Email" placeholder="Email" v-model="user.email" readonly></base-input>
                  </b-col>
                  <b-col md="4">
                    <base-input label="Role">
                      <el-select v-model="selects.simple" filterable
                                  placeholder="Select Role">
                        <el-option v-for="option in selectOptions"
                                    :key="option.role_id"
                                    :label="option.role_name"
                                    :value="option.role_id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <base-input label="Template List">
                      <el-select v-model="selectTemplateData" multiple filterable placeholder="Select KYC Template(s)">
                        <el-option Selected disabled value="x">-- Please Select KYC Template(s) --</el-option>
                        <el-option v-for="option in selectTemplateOptions" :key="option.global_template_name" :label="option.global_template_name" v-bind:value="option.global_template_id">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                  <b-col md="4">
                    <base-input label="Password" placeholder="Password" type="password" v-model="user.password"></base-input>
                  </b-col>
                  <b-col md="4">
                    <base-input label="Password Confirmation"
                      name="password_confirmation"
                      type="password"
                      placeholder="Password Confirmation"
                      v-model="user.password_confirmation">
                    </base-input>
                  </b-col>
                </b-row>

                <el-table class="table-responsive align-items-center table-flush"
                          header-row-class-name="thead-light"
                          :data="queriedData"
                          @sort-change="sortChange"
                          @selection-change="selectionChange"
                          row-key="global_template_id">
                  <el-table-column label="id"
                                  min-width="90px"
                                  prop="global_template_id"
                                  sortable>
                    <template v-slot="{row}">
                      <b>{{row.global_template_id}}</b>
                    </template>
                  </el-table-column>

                  <el-table-column label="Name"
                                  prop="global_template_name"
                                  min-width="200px"
                                  sortable>
                    <template v-slot="{row}">
                      <b>{{row.global_template_name}}</b>
                    </template>
                  </el-table-column>

                  <el-table-column label="Description"
                                  prop="global_template_description"
                                  min-width="200px"
                                  sortable>
                    <template v-slot="{row}">
                      <b>{{row.global_template_description}}</b>
                      <div>
                        <li>Total Categories: {{row.global_template_category.category_count}}</li>
                        <li>Total Sub Categories: {{row.global_template_category.sub_category_count}}</li>
                        <li>Total Questions: {{row.global_template_question.question_count}}</li>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column min-width="180px" align="center">
                    <div slot-scope="{row}" class="table-actions">
                     
                        <base-button type="primary" icon size="sm" @click.prevent="onView(row)">
                          <!-- <span class="btn-inner--icon"><i class="fas fa-file"></i></span> -->
                          <span class="btn-inner--text">View</span>
                        </base-button>
                      
                        <base-button type="primary" icon size="sm" @click.prevent="onDelete(row)">
                          <!-- <span class="btn-inner--icon"><i class="fas fa-trash"></i></span> -->
                          <span class="btn-inner--text">Delete</span>
                        </base-button>
                    </div>
                  </el-table-column>
                </el-table>

                <h5 class="text-danger"> Note : <br><br>
                  <i>
                    1. Password must be at least 8 characters in length.
                  </i>
                  <br>
                  <i>
                    2. Password must contain at least one lowercase letter. [a-z].
                  </i>
                  <br>
                  <i>
                    3. Password must contain at least one uppercase letter. [A-Z].
                  </i>
                  <br>
                  <i>
                    4. Password must contain at least one number. [0-9].
                  </i>
                  <br>
                  <i>
                    5. Password  must contain a special character from the list. [@$!%*#?&].
                  </i>
                  <br>
                </h5>

                <base-button type="primary" @click="updateData()">Create / Save</base-button>
                <base-button type="primary" @click="$router.back()">Cancel</base-button>
              </form>
            </card>

          </div>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import { Table, TableColumn, Select , Option } from 'element-ui'
import axios from 'axios'
import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import clientPaginationMixin from '@/assets/js/clientPaginationMixin'

  export default {
    mixins: [ErrorFunctionMixin, clientPaginationMixin],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    async created(){
        this.roleList();
        await this.globalTemplateList();
        this.setSelectOption();
    },
    data() {
      return {
        tableData: [],
        selectedRows: [],
        user: {
          name: null,
          email: null,
          password: null,
        },
        selects:{
          simple:[],
          template:[]
        },
        selectTemplateData: [],
        selectOptions: [],
        selectTemplateOptions: []
      }
    },
    computed:{
      selectTemplate() {
        const {selectTemplateData} = this
        return selectTemplateData
      }
    },
    watch: {
      selectTemplate: {
        handler: function(val) {
          let vm = this;
          const obj2 = this.selectTemplateOptions

          this.tableData = [];

          Object.values(val).forEach(value => {
            Object.values(obj2).forEach(obj2_value => {
              if(obj2_value.global_template_id == value)
              {
                this.tableData.push(obj2_value);
                return;
              }
            });
          });  
        },
        deep: true,

      }
    },
    methods: {
      selectionChange(selectedRows) {
        this.selectedRows = selectedRows
      },
      updateData(){
        const postData = {
          name : this.user.name,
          password : this.user.password,
          password_confirmation: this.user.password_confirmation,
          email : this.user.email,
          role_id : this.selects.simple,
          active_status : 1,
          global_template_id : this.selectTemplateData
        }

        axios.put(apiDomain + 'setup/user/' + this.$route.params.id , postData ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.$notify({
              message:
                '<b>New User : ' + response.data.data[0].name + '</b> - Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
            this.$router.back()


          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Add User")
            
        });

      },
      roleList(){
        axios.get(apiDomain + 'setup/role', { headers: getHeader() })
        .then(response => {
          this.selectOptions = response.data.data
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Show Role")
        });
      },
      globalTemplateList(){
        axios.get(apiDomain + 'templates/global_template', { headers: getHeader() })
        .then(response => {
          this.selectTemplateOptions = response.data.data
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Delete Global Template")
            
        });
        const str = JSON.stringify(this.tableData, null, 2);
        console.log(str);
      },
      setSelectOption() {
        axios.get(apiDomain + 'setup/user/' + this.$route.params.id ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.user.name      = response.data.data[0].name
            this.user.email     = response.data.data[0].email
            this.selects.simple = response.data.data[0].role.role_id

            const obj = response.data.data[0].global_template_access
            let list = [];
            Object.keys(obj).forEach(key =>{
              list.push(obj[key].global_template.global_template_id)
            })
            this.selectTemplateData = list
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Show User")
            
        });
      },
      onDelete(row) {
        this.selectTemplateData = this.selectTemplateData.filter(object => {
          return object !== row.global_template_id;
        });
      },
      onView(row) {
        // this.$router.push({path: '/templates/globalTemplateQuestions/' + row.global_template_id })
        let routeData = this.$router.resolve({path: '/templates/globalTemplateQuestions/' + row.global_template_id + '/1'});
        window.open(routeData.href, '_blank');
      }
    }
  }
</script>
<style>
.el-table .cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
  word-break: break-all;
  line-height: 23px;
}
</style>