<template> 
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Setup</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <!---
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral" >New</base-button>
          <base-button size="sm" type="neutral" >Filters</base-button>
        </b-col>
        -->
        <b-col cols="6" class="text-right">
          <el-tooltip content="Add a new External Application" placement="top">
            <base-button type="primary" icon size="sm" @click="addExternalApplication()">
              <!-- <span class="btn-inner--icon"><i class="fas fa-edit"></i></span> -->
              <span class="btn-inner--text">Add</span>
            </base-button>

          </el-tooltip>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <external-application-table></external-application-table>
    </b-container>
  </div>
</template>
<script>
  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
  import ExternalApplicationTable from "@/views/Setup/ExternalApplicationFolder/ExternalApplicationTables";

  export default {
    components: {
      ExternalApplicationTable,
      RouteBreadCrumb,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    data() {
      return {
      };
    },
    methods: {
      addExternalApplication(){
        this.$router.push({path: '/setup/addExternalApplications' })
      },
    }
  };
</script>
