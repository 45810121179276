<template>
  <div>
    <base-header class="pb-6">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Category</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </b-col>
        <!--
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col>
        -->
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Edit Category</h3>
              <!-- Card body -->
              <form>
                <!-- Input groups with icon -->
                <b-row>
                  <b-col md="6">
                    <base-input  label="Category Name" placeholder="Category name" v-model="category.category_name"></base-input>
                  </b-col>
                </b-row>

                <base-button type="primary" @click="updateData()">Create / Save</base-button>
                <base-button type="primary" @click="$router.back()">Cancel</base-button>
              </form>
            </card>

          </div>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
  import { Select , Option } from 'element-ui'
  import axios from 'axios'
  import {apiDomain, getHeader} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components:{
      [Select.name]: Select,
      [Option.name]: Option,
    },   
    data() {
      return {
        category: {
          category_name: null,
        },
      }
    },
    created(){
        axios.get(apiDomain + 'setup/category/' + this.$route.params.id ,{ headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.category.category_name = response.data.data[0].category_name
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Show User")
            
        });
    },
    methods: {
      updateData(){
          const postData = {
            category_name : this.category.category_name,
          }
          
          const str = JSON.stringify(postData);
          console.log(str);
          console.table(postData);

          axios.put(apiDomain + 'setup/category/' + this.$route.params.id , postData ,{ headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              this.$notify({
                message:
                  '<b>Edit Category : ' + this.category.category_name + '</b> - Successfully Saved.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });
              this.$router.back()
            }
          })
          .catch ( error => {
              console.log(error.response.status)
              this.errorFunction(error, "Add Category")
              
          });

      },
    }
  }
</script>
